<template>
  <div class="Main" style="overflow-y: scroll">
    <div class="tableOne information_table">
      <div class="search_and_profil add_search_and_profile">
        <div class="profile_box">
          <div class="cercle">
            <b-icon
              icon="circle-fill"
              animation="throb"
              font-scale="2"
              style="width: 20px; height: 20px"
            ></b-icon>
          </div>
          <div class="title">
            <h4>{{ this.user?.role.toUpperCase() }}</h4>
          </div>
          <div class="chevron">
            <b-icon
              icon="chevron-down"
              style="width: 15px; height: 15px"
            ></b-icon>
          </div>
        </div>
      </div>
      <b-overlay :show="loading" no-wrap></b-overlay>
      <div class="title_boxx info_box">
        <div class="back_link">
          <template v-if="data && data.title && data.title.ru">
            <p>{{ data.title.ru }}</p>
            {{ data.message }}
          </template>
        </div>
      </div>
      <div class="data_location">
        <div class="btn_box">
          <template v-if="data && data.status && data.status.ru">
            <p>{{ data.status.ru }}</p>
          </template>
        </div>
        <div class="data_box">
          <div class="icon">
            <b-icon
              icon="calendar3"
              style="width: 20px; height: 20px; color: #2f3641"
            ></b-icon>
          </div>
          <div class="data_from">
            <template v-if="data && data.start_date">
              <p>{{ filterDate(data.start_date) }}</p>
            </template>
          </div>
          <div class="data_to">
            <b-icon
              icon="dash"
              style="
                width: 20px;
                height: 20px;
                margin-left: -10px;
                color: #2f3641;
              "
            ></b-icon>
            <template v-if="data && data.end_date">
              <p>{{ filterDate(data.end_date) }}</p>
            </template>
          </div>
        </div>
        <div class="location_box">
          <template v-if="data && data.address">
            <div class="geo_loc">
              <b-icon
                icon="geo-alt"
                style="width: 20px; height: 20px; color: #2f3641"
              ></b-icon>
            </div>
            <p>{{ data.address }}</p>
          </template>
        </div>
      </div>

      <div class="row row_carts">
        <div class="col-2 col-sm-2 col-md-2 col-lg-3 card_line">
          <div class="card_box card1">
            <div class="card_iconn">
              <b-icon
                icon="clock"
                style="width: 45px; height: 45px; color: #354b9c"
              ></b-icon>
            </div>
            <div class="conuter">
              <template
                v-if="
                  data && (data.visits_count === 0 || data.visits_count > 0)
                "
              >
                <p>
                  {{ data.visits_count }}
                </p>
              </template>
            </div>
            <div class="title">
              <p>Прибыл</p>
            </div>
          </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2 col-lg-3 card_line">
          <div class="card_box">
            <div class="card_iconn">
              <b-icon
                icon="envelope"
                style="width: 45px; height: 45px; color: #354b9c"
              ></b-icon>
            </div>
            <div class="conuter">
              <template
                v-if="
                  data && (data.emails_count === 0 || data.emails_count > 0)
                "
              >
                <p>
                  {{ data.emails_count }}
                </p>
              </template>
            </div>
            <div class="title">
              <p>Отправленные Email</p>
            </div>
          </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2 col-lg-3 card_line">
          <div class="card_box card_box3">
            <div class="card_iconn">
              <b-icon
                icon="person-plus-fill"
                style="width: 45px; height: 45px; color: #354b9c"
              ></b-icon>
            </div>
            <div class="conuter">
              <template
                v-if="
                  data &&
                  (data.online_participants_count === 0 ||
                    data.online_participants_count > 0)
                "
              >
                <p>
                  {{ data.online_participants_count }}
                </p>
              </template>
            </div>
            <div class="title">
              <p>Онлайн-Регистрация</p>
            </div>
          </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2 col-lg-3 card_line">
          <div class="card_box card2">
            <div class="card_iconn">
              <b-icon
                icon="people"
                style="width: 45px; height: 45px; color: #354b9c"
              ></b-icon>
            </div>
            <div class="conuter">
              <template
                v-if="
                  data && (data.declined_count === 0 || data.declined_count > 0)
                "
              >
                <p>
                  {{ data.declined_count }}
                </p>
              </template>
            </div>
            <div class="title">
              <p>Отменёные участники</p>
            </div>
          </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2 col-lg-12 card_line">
          <div class="card_box card2" style="margin: 10px 0 !important">
            <div class="card_iconn">
              <b-icon
                icon="people"
                style="width: 45px; height: 45px; color: #354b9c"
              ></b-icon>
            </div>
            <div class="conuter">
              <template
                v-if="
                  data &&
                  (data.participants_count === 0 || data.participants_count > 0)
                "
              >
                <p>
                  {{ data.participants_count }}
                </p>
              </template>
            </div>
            <div class="title">
              <p>Общее к.участников</p>
            </div>
          </div>
        </div>
      </div>
      <div class="graph_box">
        <div id="chart" v-if="series[0].data.length > 11">
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import { instance } from "@/utils/composables";
import router from "@/router";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      // Form information
      data: null,
      events: [],
      selectedEventId: null,
      series: [
        {
          name: "Участники",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          position: "top",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          text: "Число участников",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      },
      copy_link: "https://event.net",
      loading: true,
      user: null
    };
  },
  props: ["message"],
  computed: {
    isAdmin() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    onChangeEvent() {
      this.data = [];
      this.series[0].data = [];
      this.getInformation();
    },
    filterDate(string) {
      const date = string.split(" ")[0];
      const time = string.split(" ")[1];
      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth(); // wrong value
      const day = new Date(date).getDay(); // wrong value
      // return `${month}/${day}/${year} ${time}`;
      return `${date} ${time}`;
    },
    // getInformation() {
    //   console.log("message");
    //   this.loading = true;
    //   instance
    //     .get(`/event/${this.selectedEventId}/info/`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.accessToken,
    //       },
    //     })
    //     .then(({ data }) => {
    //       this.data = data;
    //       this.series[0].data = [];
    //       const allData = [];
    //       for (let i of this.data.months) {
    //         const data = Object.keys(i)[0];
    //         allData.push(i[data]);
    //       }
    //       this.series[0].data = allData;
    //     })
    //     .catch((err) => {
    //       this.$toast.error(err.response.data.message);
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
  },
  // mounted() {
  //   if (JSON.parse(localStorage.getItem("user"))["event"]) {
  //     this.selectedEventId = JSON.parse(localStorage.getItem("user"))[
  //       "event"
  //     ].id;
  //     this.getInformation();
  //   } else {
  //     this.selectedEventId = 2;
  //     this.getInformation();
  //   }
  // },
  async mounted() {
    try {
      await this.$store.dispatch(
        "getInformation",
        localStorage.getItem("event")
      );
      this.user = JSON.parse(localStorage.getItem("user"));
      this.data = this.$store.state.info;
      this.series[0].data = [];
      const allData = [];
      for (let i of this.data.months) {
        const data = Object.keys(i)[0];
        allData.push(i[data]);
      }
      this.series[0].data = allData;
      // console.log(data);
    } catch (error) {
      this.$toast.error(error.response.data.message);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #f8f7ff;
}
</style>
