<template>
  <div class="main">
    <div class="page-header">
      <h2 class="title">Детали полета</h2>
      <div class="ations">
        <div class="double_btn">
          <router-link :to="{name:'FlightDetailsFormAdd'}" class="add_btnn">
            <button>
              <span
              ><b-icon
                  class="icon"
                  icon="plus"
                  style="width: 20px; height: 20px"
              ></b-icon
              ></span>
              Добавить информацию о рейсе
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="tableOne">
      <div class="table_box">
        <b-table
            id="my-table"
            class="table_in"
            :table-variant="tableVariant"
            show-empty
            :items="[data]"
            :fields="fields"
            ref="selectableTable"
            responsive
            fixed
            small
        >
          <template #cell(arrival_date)="data">
            {{ data.item?.flight_arrival_datetime?.split('T')[0] }}
            <img v-if="data.item?.flight_arrival_datetime"
                 style="height: 30px; margin-left: 4px; transform: rotate(45deg)" src="@/assets/airplane.png"/>
          </template>
          <template #cell(arrival_time)="data">
            {{ data.item?.flight_arrival_datetime && moment(data.item?.flight_arrival_datetime).format("HH:mm") }}
            <img v-if="data.item?.flight_arrival_datetime"
                 style="height: 30px; margin-left: 4px; transform: rotate(45deg)" src="@/assets/airplane.png"/>
          </template>
          <template #cell(departure_date)="data">
            <img v-if="data.item?.flight_departure_datetime" style="height: 30px; margin-right: 4px"
                 src="@/assets/airplane.png"/>
            {{ data.item?.flight_departure_datetime?.split('T')[0] }}
          </template>
          <template #cell(departure_time)="data">
            <img v-if="data.item?.flight_departure_datetime" style="height: 30px; margin-right: 4px"
                 src="@/assets/airplane.png"/>

            {{ data.item?.flight_departure_datetime && moment(data.item?.flight_departure_datetime).format("HH:mm") }}
          </template>
          <template #cell(action)="data">
            <div class="boxx">
              <router-link
                  :to="{name:'FlightDetailsFormEdit'}"
              >
                <div class="edit_box">
                  <b-icon
                      icon="pencil"
                      style="width: 15px; height: 15px; color: #fff"
                  ></b-icon>
                </div>
              </router-link>
            </div>
          </template>
        </b-table>
        <b-overlay :show="loading" no-wrap></b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import {instance} from "@/utils/composables";
import moment from "moment";

export default {
  name: "FlightDetails",
  computed: {
    moment() {
      return moment
    }
  },
  data() {
    return {
      loading: false,
      data: {},
      tableVariant: "light",
      fields: [
        {
          key: "flight_number",
          label: "Номер авиарейса"
        },
        {
          key: "departure_date",
          label: "Дата вылета"
        },
        {
          key: "departure_time",
          label: "Время вылета"
        },
        {
          key: "arrival_date",
          label: "Дата прилета"
        },
        {
          key: "arrival_time",
          label: "Время прилета"
        },
        {
          key: "action",
          label: "Действие",
          stickyColumn: true
        }
      ]
    }
  },
  methods: {
    getFlightDetail() {
      this.loading = true;
      instance.get("/user/flight-detail/", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(({data}) => {
        this.data = data
        console.log(data)
      }).catch(({error}) => {
        this.$toast.error(error.response.data.message)
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.getFlightDetail()
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 100%;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECEFF8;
}

.table > :not(:first-child) {
  border-top: none !important;
}

.table td {
  vertical-align: middle;
}

.table_in {
  height: calc(100vh - 270px);
  margin-bottom: 0 !important;
}

thead th {
  vertical-align: middle !important;
}

button {
  border: none;
  outline: none;
  padding: 10px 20px;
  background: #354b9c;
  color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  font-weight: 500;
  font-size: 16px;
}
</style>