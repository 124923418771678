<template>
  <div class="guided_tours container">
    <b-tabs content-class="mt-3">
      <b-tab title="One-day tour to Samarkand from Tashkent" active>
        <div>
          <p>
            <strong>Tour itinerary:</strong> Tashkent – Samarkand – Tashkent
          </p>
          <p><strong>Season:</strong> all the year round</p>
          <p><strong>Days: </strong>1 | <strong>Nights: </strong>0</p>
          <p>
            <strong
            >One day tour to Samarkand is a promising wonderful journey to one
              of the greatest cities in the world – legendary Samarkand. </strong
            >Take a round trip from Tashkent by high-speed train ‘Afrosiyob’ –
            the road will take only 2 hours.
          </p>
          <p>
            Samarkand welcomes with monuments of stunning beauty &amp; majestic
            blue domes reviving the time of the Great Tamerlane age. All of them
            represent the greatness &amp; glory of Temurids, their power and
            influence.
          </p>
          <p>
            You will visit all main attractions: Gur Emir, the mausoleum of Amir
            Temur and his male relatives, Registon Square and its 3 famous
            madrassahs, Ulugbek’s observatory, Bibi Khanym mosque, Siyab bazaar,
            etc. During the one-day excursion to city you will be accompanied by
            professional English-speaking guide. Our driver will transfer you
            back to the railway station and say goodbye with all hospitality of
            people.
          </p>
          <p>
            <strong>Day 1 –&nbsp;</strong
            ><strong>Tashkent – Samarkand – Tashkent</strong>
          </p>
          <p>&nbsp;</p>
          <p>Meet the driver at the hotel lobby.</p>
          <p>
            <strong>07:00</strong> Transfer from hotel to Tashkent railway
            station.
          </p>
          <p>
            <strong>08:00</strong> Departure for Samarkand by the tourist train
            <strong>Afrosiab</strong>.
          </p>
          <p><strong>10:10</strong> Arrival in Samarkand.</p>
          <p>
            At the meeting zone meet the driver and guide who will wait for you
            with a sign “Mercury DMC”.
          </p>
          <p>
            Transfer to the old part of Samarkand and start sightseeing program:
          </p>
          <p>
            – <strong>Gur Emir Mausoleum</strong> – a burial vault of Timur the
            Great and his descendants decorated with gold ornaments and
            considered as one of the most significant places in the world;
          </p>
          <p>
            – Continue to the famous <strong>Registan Square</strong> – the
            heart of Samarkand and a real masterpiece of Central Asian medieval
            architecture consists of three grandiose buildings: Ulughbek
            Madrassa, Madrassa Sherdor, and Madrassa Tilla Kori.
          </p>
          <p>
            – <strong>Bibi Khanim Mosque</strong> – the biggest medieval
            building in the city which was one of the most magnificent mosques
            in the Islamic world in 15th Century;
          </p>
          <p>
            – <strong>Shahi Zinda Necropolis</strong> – unique complex of
            Mausoleums&nbsp; formed by standing on both sides of the cenotaph
            (an imaginary grave) belonging to the Muslim person named Saint
            Abbas ibn-Kussam;
          </p>
          <p>
            – <strong>Ulugbek’s Observatory</strong> – the finest astronomical
            centers in the medieval world was built by order of Ulugbek, an
            outstanding scholar and astronomer;
          </p>
          <p>– <strong>Samarkand bazaar – Siyab</strong>.</p>
          <p><strong>17:00</strong> Transfer to Samarkand railway station.</p>
          <p>
            <strong>18:00</strong> Departure for Tashkent by the tourist train
            <strong>Afrosiab</strong> or <strong>Sharq</strong>.
          </p>
          <p><strong>20:10</strong> Arrival in Tashkent.</p>
          <p>
            Meet the driver at the meeting zone. Transfer to the hotel. End of
            the tour.
          </p>
          <p><br/><br/></p>
          <p><strong>Price per person:</strong></p>
          <table width="100%">
            <tbody>
            <tr>
              <td width="287">
                <p><strong>Person</strong></p>
              </td>
              <td width="287">
                <p><strong>Standart</strong></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>1</strong></p>
              </td>
              <td><p>$250</p></td>
            </tr>
            <tr>
              <td>
                <p><strong>2-3</strong></p>
              </td>
              <td><p>$200</p></td>
            </tr>
            <tr>
              <td>
                <p><strong>4-5</strong></p>
              </td>
              <td><p>$180</p></td>
            </tr>
            <tr>
              <td>
                <p><strong>6-12</strong></p>
              </td>
              <td><p>$120</p></td>
            </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <p><strong>Price included</strong></p>
          <ul>
            <li>
              Train tickets Tashkent – Samarkand – Tashkent by trains
              Afrosiab/Sharq.
            </li>
            <li>Transport during all the tour time in Samarkand;</li>
            <li>Licensed professional guide service in Samarkand;</li>
            <li>Entry tickets to the museums and mausoleums as per program;</li>
          </ul>
          <p>&nbsp;</p>
          <p><strong>Not included</strong></p>
          <ul>
            <li>Meals (lunch and dinner);</li>
            <li>
              Other services which are not specified under “Tour price
              includes”.
            </li>
          </ul>
        </div>
      </b-tab>
      <b-tab title="2 days to Bukhara from Tashkent">
        <div>
          <div class="container-fluid">
            <p>
              <strong>Tour itinerary:</strong> Tashkent – Bukhara – Tashkent
            </p>
            <p><strong>Season:</strong> all year round</p>
            <p><strong>Days: </strong>2 | <strong>Nights: </strong>1</p>
            <p>
              <strong>Two days to tour Bukhara from Tashkent by train.</strong>
            </p>
            <p>
              Elder holy Bukhara – an oriental dream lost somewhere in the
              sands. One of the 8 holy muslim cities, annouced by Prophet
              himself, Bukhara remains to be one of the most beautiful places in
              Uzbekistan.
            </p>
            <p>
              <strong>Tours to Bukhara are usually walking</strong>. You will
              spend around 5 hours enjoying the city, sights and the cuisine.
              Bukhara is also very safe, kindhearted and warm. Many famous
              people are born/lived/buried there and their mausoleums are in our
              tour checkpoints.
            </p>
            <p><em>Welcome to Bukhara!</em></p>
            <p>
              <strong>Day 1 –&nbsp;</strong><strong>Tashkent – Bukhara</strong>
            </p>
            <p>Meet the driver at the hotel in Tashkent.</p>
            <p>
              <strong>06:30</strong> Transfer to the train station in
              <strong>Tashkent</strong>.
            </p>
            <p>
              <strong>07:28</strong> Departure for Bukhara by high-speed train
              <strong>“Afrosiyob”</strong>.
            </p>
            <p>
              <strong>11:19</strong>&nbsp; Arrival in <strong>Bukhara</strong>.
            </p>
            <p>
              Come out of the terminal and meet the driver who will wait for you
              with a tablet “Mercury DMC”.
            </p>
            <p>
              Transfer to the hotel to drop off the bags and meet the guide at
              the hotel.
            </p>
            <p>
              <strong>Start WALKING sightseeing program in </strong
              ><strong>Bukhara</strong><strong> (5-6 hours):</strong>
            </p>
            <p>
              – <strong>Ensemble Lyabi Hauz</strong> (Nadir Divanbegi Madrassa,
              Khanaka, Kukeldash Madrassa and the monument of Khodja Nasreddin);
            </p>
            <p>
              – <strong>Magoki Attari Mosque</strong> is one of the oldest
              mosques survived in Bukhara from the time before the Mongolian
              invasion;
            </p>
            <p>
              – <strong>Abdullakhan’s Tim</strong> with numerous handicraft
              shops of souvenirs, ceramics, national clothes, and carpets;
            </p>
            <p>
              – Caravanserai Sayfuddin (the Center of
              <strong>Handicraft</strong> Development of Bukhara);
            </p>
            <p>
              – There are three trading domes or so called covered bazaars Toki
              Sarrofon, Toki Telpak Furushon, and Toki Zargaron preserved from
              the XVI century and these bazaars were the centers for trade of
              silk, jewelry and even for money exchange. Today as well, one can
              enjoy and purchase different souvenirs of a wide range, from small
              souvenir magnets to the famous Bukhara carpets;
            </p>
            <p>
              – Visit Ulugbek and <strong>Abdulazizkhan Madrassas</strong> or
              so-called Kosh (pare) madrassas opposite each other with the
              distinctive style of facade ornamental decoration;
            </p>
            <p>
              – <strong>Poi Kalon Square</strong> with Kalon Minaret and Mosque,
              Mir Arab Madrassa – the heart of Bukhara, a monumental complex
              impresses the imagination;
            </p>
            <p>
              – <strong>Ark Fortress</strong> – the oldest citadel dating back
              to I century BC where one can feel the history of the great
              fortress covered in beautiful legends and myths.
            </p>
            <p>Optionally can be added by request:</p>
            <p>
              Visit <strong>Samanids mausoleum</strong> (IX century), a monument
              of early medieval architecture.
            </p>
            <p>Resting time in the evening. Overnight at the hotel.</p>
            <p>
              <strong>Day 2 –&nbsp;</strong><strong>Bukhara – Tashkent</strong>
            </p>
            <p>Breakfast at the hotel.</p>
            <p>
              <strong>09:00</strong> Meet the guide and driver at the lobby.
            </p>
            <p><strong>Continue Bukhara sightseeing tour visiting:</strong></p>
            <p>
              – Last Bukhara’s Emir’s summer residence
              “<strong>Sitorai-Mokhi-Khossa</strong>“;
            </p>
            <p>
              – <strong>Bakhauddin Naqshbandi</strong> mausoleum (the founder of
              Naqshbandi Sufi Spiritual order) Located near Bukhara the memorial
              complex of Bakhauddin Nakshbandi is considered as one of the most
              significant religious places of Bukhara that attract many
              visitors;
            </p>
            <p>
              – <strong>Chor-Minor Madrasah</strong>. Chor-Minor is known as
              madrasah Halif Niyazkul (in Persian “Four minarets”) was built by
              a merchant in 1807 in Bukhara.
            </p>
            <p>
              <strong>14:30 </strong>Transfer to the train station in
              <strong>Bukhara</strong>.
            </p>
            <p>
              <strong>15:50 </strong>Departure for Tashkent by high-speed train
              <strong>“Afrosiyob”</strong>.
            </p>
            <p><strong>19:44</strong> Arrival in <strong>Tashkent</strong>.</p>
            <p>Come out of the terminal and meet the driver.</p>
            <p><strong>Transfer</strong> to the hotel/airport.</p>
            <p>
              <strong><u>End of the tour.</u></strong>
            </p>
            <p><strong>Price per person:</strong></p>
            <table width="100%">
              <tbody>
              <tr>
                <td width="192">
                  <p><strong>Person</strong></p>
                </td>
                <td width="192">
                  <p><strong>Standart</strong></p>
                </td>
                <td width="192">
                  <p><strong>Comfort</strong></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><strong>1</strong></p>
                </td>
                <td><p>$500</p></td>
                <td><p>$550</p></td>
              </tr>
              <tr>
                <td>
                  <p><strong>2-3</strong></p>
                </td>
                <td><p>$450</p></td>
                <td><p>$500</p></td>
              </tr>
              <tr>
                <td>
                  <p><strong>4-5</strong></p>
                </td>
                <td><p>$400</p></td>
                <td><p>$450</p></td>
              </tr>
              <tr>
                <td>
                  <p><strong>Single supplement</strong></p>
                </td>
                <td><p>$35</p></td>
                <td><p>$40</p></td>
              </tr>
              </tbody>
            </table>
            <p><strong>Price included</strong></p>
            <ul>
              <li>Accommodation in standard double/twin rooms in Bukhara;</li>
              <li>
                All transfers as per program by comfortable tourist class
                transport as per road conditions including transfers to-from
                train stations;&nbsp;&nbsp;&nbsp;
              </li>
              <li>
                English-speaking professional licensed local guide in Bukhara (2
                standard excursions);
              </li>
              <li>Meals: breakfasts at the hotel;</li>
              <li>
                Single entry tickets to the sites, museums, mausoleums as per
                program;
              </li>
              <li>
                Train tickets Tashkent – Bukhara – Tashkent by speed train
                “Afrosiab”/Sharq” (economy class);
              </li>
              <li>Tourist tax per person.</li>
            </ul>
            <p><strong>Not included</strong></p>
            <ul>
              <li>International flights to/from Tashkent;</li>
              <li>Accommodation in Tashkent (can be added by request);</li>
              <li>Photo/video fees (professional camera);</li>
              <li>E-visa to Uzbekistan (if required);</li>
              <li>Meals: lunches and dinners;</li>
              <li>Extra payment for 1 class train tickets (by request).</li>
              <li>
                VAT 15 %, personal travel/medical insurance, portage, tips,
                personal expenses, and other services not specified under “Tour
                Price Includes”
              </li>
            </ul>
            <p><strong>Notes</strong></p>
            <p>
              – Check-in 14.00, check-out time 11.00. No early check-in/ late
              check-out is included unless indicated;
            </p>
            <p>
              <strong
              >– Please note that the tour price does not include seasonal VAT
                15 %, VAT can be added to the basic tour price.</strong
              >
            </p>
            <p>
              – Please note that the drivers do not speak English or can speak
              only basic English;
            </p>
            <p>
              – All the changes in the basic itinerary, the timing for transfers
              depending on the international flight departure/arrival time are
              to be discussed and pre-agreed;
            </p>
            <p>
              – Please note that the train trip/s can be replaced for transfer/s
              by car depending on train tickets availability and trains
              schedule;
            </p>
            <p>
              – After the date of publication, any changes in the hotels,
              air/train ticket prices, tax increase, and exchange rate
              fluctuation may influence the tour prices;
            </p>
            <p>
              – Mercury DMC is not responsible for the force majeure occasions
              (weather conditions during the tour, repairing-reconstructing
              works at some parts of roads, government restrictions).
            </p>
          </div>
        </div>
      </b-tab>
      <b-tab title="One-day tour to Tashkent">
        <div>
          <p><strong>Day 1</strong></p>
          <p><strong>09:00</strong> Meet the guide at the hotel lobby</p>
          <p>City tour of Tashkent with local guide.</p>
          <p> Transfer back to hotel</p>

          <p>
            Meet with our driver, who will wait for you with a sign
            <strong>“Mercury DMC”. </strong>Transfer to the hotel. End of the
            tour.
          </p>

          <p><strong>VISITING MONUMENTS</strong></p>
          <ul>
            <li> Amir Timur Square</li>
            <li> Theater Square with the Opera House</li>
            <li> Independence Square</li>
            <li> Earthquake Memorial</li>
            <li> Khast Imam Square</li>
            <li> Chorsu bazaar</li>
            <li> Tashkent Metro</li>
          </ul>

          <p><strong>Price per person:</strong></p>
          <table width="100%">
            <tbody>
            <tr>
              <td width="50%">
                <p><strong>Person</strong></p>
              </td>
              <td width="50%">
                <p><strong>Standart</strong></p>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <p><strong>1</strong></p>
              </td>
              <td width="50%"><p>$200</p></td>
            </tr>
            <tr>
              <td width="50%">
                <p><strong>2-3</strong></p>
              </td>
              <td width="50%"><p>$150</p></td>
            </tr>
            <tr>
              <td width="50%">
                <p><strong>4-5</strong></p>
              </td>
              <td width="50%"><p>$100</p></td>
            </tr>
            <tr>
              <td width="50%">
                <p><strong>6-12</strong></p>
              </td>
              <td width="50%"><p>$70</p></td>
            </tr>
            </tbody>
          </table>
          <p><strong>Price included</strong></p>


          <ul>
            <li>
              - Transport for all tour time;
            </li>
            <li> - English speaking licensed guide service;</li>
            <li>
              - Single entry tickets to the sites as per program;
            </li>
            <li>
              - Metro entry ticket;
            </li>
          </ul>
          <p><strong>Not included</strong></p>
          <ul>
            <li>- Fees for the use of professional photo/video;</li>
            <li>- Meals, personal expenses and other services not specified under “Tour Price Includes”.</li>
          </ul>

        </div>
      </b-tab>
    </b-tabs>
    <div class="tour_form">
      <h3>Tour Booking</h3>
      <form @submit.prevent="sendEmail" class="d-flex flex-wrap" autocomplete="off">
        <select name="prefix" class="form_input" v-model="form.prefix" required>
          <option selected value="mr">Mr.</option>
          <option value="mrs">Mrs.</option>
        </select>
        <input
            type="text"
            class="form_input"
            required
            v-model="form.first_name"
            placeholder="First name"
        />
        <input
            type="text"
            v-model="form.last_name"
            class="form_input"
            required
            placeholder="Last name"
        />
        <input
            type="text"
            class="form_input"
            v-model="form.citizenship"
            required
            placeholder="Citizenship"
        />

        <input
            type="email"
            v-model="form.email"
            class="form_input"
            required
            placeholder="E-mail"
        />
        <input
            type="tel"
            class="form_input"
            v-model="form.phone_number"
            required
            placeholder="Phone"
        />
        <select
            class="form_input"
            name="tours"
            v-model="form.tour"
            required
        >
          <option selected value="One-day tour to Samarkand from Tashkent">
            One-day tour to Samarkand from Tashkent
          </option>
          <option value="2 days to Bukhara from Tashkent">
            2 days to Bukhara from Tashkent
          </option>
          <option value="One-day tour to Tashkent">
            One-day tour to Tashkent
          </option>
        </select>
        <select
            class="form_input"
            v-model="form.travelers"
            name="travelers"
            required
        >
          <option value="" disabled>Number of travelers</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
        <div style="margin-top: 10px; margin-left: 10px">
          <textarea class="form_input-textarea" style="width: 100%" placeholder="Comments and additional information"
                    v-model="form.comment" id=""
                    cols="111"
                    rows="4"></textarea>
        </div>
        <div class="order_button" style="margin-top: 10px; margin-left: 10px">
          <b-button variant="primary" type="submit">Send Order</b-button>
        </div>
      </form>
    </div>
    <b-overlay :show="loading" style="z-index: 2000" no-wrap></b-overlay>
  </div>
</template>
<script>
import {instance} from "@/utils/composables";

export default {
  name: "GuidedTours",
  data() {
    return {
      loading: false,
      form: {
        user: '',
        event: '',
        prefix: "mr",
        first_name: "",
        last_name: "",
        citizenship: "",
        email: "",
        phone_number: "",
        tour: "One-day tour to Samarkand from Tashkent",
        travelers: "",
        comment: "",
      },
    };
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    async sendEmail() {
      this.form.user = this.userInfo.id;
      this.form.event = this.userInfo.event?.id;
      this.loading = true;

      let sendForm = new FormData();
      sendForm.append('user', this.form.user);
      sendForm.append('event', this.form.event);
      sendForm.append('prefix', this.form.prefix);
      sendForm.append('first_name', this.form.first_name);
      sendForm.append('last_name', this.form.last_name);
      sendForm.append('citizenship', this.form.citizenship);
      sendForm.append('email', this.form.email);
      sendForm.append('phone_number', this.form.phone_number);
      sendForm.append('tour', this.form.tour);
      sendForm.append('travelers', this.form.travelers);
      sendForm.append('comment', this.form.comment);
      await instance
          .post("user/tour/booking/", sendForm)
          .then(() => {
            this.form = {
              user: '',
              event: '',
              prefix: "mr",
              first_name: "",
              last_name: "",
              citizenship: "",
              email: "",
              phone_number: "",
              tour: "One-day tour to Samarkand from Tashkent",
              travelers: "",
              comment: "",
            };
            this.$toast.success("Successfully sent");
          })
          .catch((err) => {
            this.$toast.error("Error");
            console.log("Error ", err);
          }).finally(() => {
            this.loading = false
          });
    },
  }
};
</script>
<style scoped>
.form_input {
  height: 45px;
}

.form_input, .form_input-textarea {
  margin: 5px;
  border: none;
  border-radius: 10px;
  padding: 0 5px;
}
</style>