<template>
  <div class="main">
    <div class="page-header">
      <h2 class="title">Transportation booking</h2>
      <div class="ations">
      </div>
    </div>

    <div class="form">
      <iframe
          ref="transport"
          style='height:calc(100vh - 100px); width:100%; padding-top: -30px;overflow: hidden'
          src="https://book.mylimobiz.com/v4/uzviptrans"></iframe>
    </div>
    <b-overlay :show="loading" no-wrap></b-overlay>
  </div>
</template>

<script>
export default {
  name: "BookedTransports",
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    this.$refs.transport.onload = () => {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 100%;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
}
</style>