import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import NotificationList from '@/components/AlertComponents/NotificationList.vue'
import Input from '@/components/Inputs/Input.vue'
import VueApexCharts from 'vue-apexcharts'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/Styles/main.scss'
import '@/utils/setAuthHeader'
import Vue2Editor from "vue2-editor";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import moment from "moment";
// import WebCam from 
import WebCam from "vue-web-cam"
import {instance} from "@/utils/composables"

if (localStorage.jwtToken) {
    setAuthHeader(localStorage.jwtToken);
} else {
    setAuthHeader(false);
}
Vue.use(WebCam)
Vue.use(BootstrapVue)
Vue.use(VueApexCharts)
Vue.use(IconsPlugin)
Vue.use(Vue2Editor)
Vue.use(VueToast, {
    position: "top-right"
});
Vue.component('notes', NotificationList)
Vue.component('apexchart', VueApexCharts)

Vue.component('my-input', Input)
import Vuelidate from 'vuelidate'
import setAuthHeader from '@/utils/setAuthHeader'

Vue.use(Vuelidate)
Vue.prototype.$moment = moment;
Vue.prototype.$api = instance;
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')