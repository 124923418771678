<template>
  <div class="Main" style="overflow-y:scroll">
    <div class="tableOne">
      <div class="search_and_profil">
        <div class="input_search">
          <label for="inputt" class="iconn">
            <b-icon
                class="icon"
                icon="search"
                style="width: 20px; height: 20px"
            ></b-icon>
          </label>
          <div class="input_box">
            <input
                class="imput_in"
                type="search"
                id="inputt"
                v-model="filter"
                @input="onInputSearch"
                placeholder="Поиск"
            />
          </div>
        </div>
      </div>
      <div class="title_boxx">
        <div>
          <h2>Участники с полномочиями</h2>
        </div>
        <div class="double_btn">
          <b-form-select
              v-model="selectedEventId"
              size="md"
              style="width: 300px;padding: 0 5px"
              @change="onChangeEvent"
          >
            <b-form-select-option v-for="event in events" :value="event.id">{{ event.title.en }}</b-form-select-option>
          </b-form-select>
        </div>
      </div>
      <div class="table_box">
        <b-table
            id="my-table"
            class="table_in"
            show-empty
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            ref="selectableTable"
            :filter-included-fields="filterOn"
            striped
            responsive
            :hover="true"
        >
          <template #cell(title)="data">
            <div>
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(role)="data">
            <b-form-tag
                style="font-weight: bold;"
                disabled
                :variant="data.item.role ==='user'?'success':data.item.role ==='admin'?'primary':'danger'"
            >{{ data.item.role?.toUpperCase() }}
            </b-form-tag>
          </template>
        </b-table>
        <!-- loading bar -->
        <b-overlay :show="loading" no-wrap></b-overlay>

        <!-- Pagination -->
        <div class="box_pagination" v-if="!loading">
          <div>Все: <strong> {{ totalRows }}</strong></div>
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              @change="onChangePagination"
              aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {instance} from "@/utils/composables";

export default {
  name: "CredentialUsers",
  data() {
    return {
      loading: false,
      items: [],
      fields: [
        {
          key: "first_name",
          label: "Имя",
          class: "text-center",
          sortable: true,
        },
        {
          key: "last_name",
          label: "Фамилия",
          class: "text-start",
          sortable: true,
        },
        {
          key: "role",
          label: "Статус",
          sortable: true,
        },
        {
          key: "date_joined",
          label: "Дата регистрации",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        }
      ],
      perPage: 15,
      totalItems: 1,
      active: true,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: "",
      filterOn: [],
      totalRows: 1,
      events: [],
      selectedEventId: null,
    }
  },
  computed: {
    currentPage() {
      return this.$route.query.page ? this.$route.query.page : 1
    }
  },
  methods: {
    onInputSearch() {
      clearTimeout(this.timeout)
      if (this.$route.query.page !== '1') {
        this.$router.replace({query: {page: '1'}})
      }
      this.timeout = setTimeout(() => this.getUsers(), 1000)

    },
    onChangePagination(val) {
      this.$router.replace({query: {page: val}}).then(() => {
        this.getUsers()
      })
    },

    onChangeEvent() {
      this.items = [];
      this.getUsers()
    },
    getUsers() {
      this.loading = true;
      let page = this.$route.query.page ? this.$route.query.page : 1

      instance.get(`/user/?event=${this.selectedEventId}&role=user&page=${page}&page_size=${this.perPage}&search=${this.filter}`, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(({data}) => {
        this.items = data.results;
        this.totalRows = data.count
      }).catch(({response}) => {
        this.$toast.error(response.data.message)
      }).finally(() => {
        this.loading = false
      });
    },
    getEvents() {
      this.loading = true
      instance.get("/event/?page=1&page_size=100", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(({data}) => {
        this.events = data.results;
        this.selectedEventId = data.results[0].id
        this.getUsers();
      }).catch(({response}) => {
        this.$toast.error(response.data.message);
        this.loading = false
      });
    }
  },
  mounted() {
    this.getEvents();
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #f8f7ff;
}

.table > :not(:first-child) {
  border-top: none !important;
}

.table td {
  vertical-align: middle;
}

.table_in {
  height: calc(100vh - 195px);
  margin-bottom: 0 !important;
}

thead th {
  vertical-align: middle !important;
}

.box_pagination {
  margin-top: 10px;
}
</style>