import Vue from 'vue'
import VueRouter from 'vue-router'
import {Navigations} from "@/router/navigations";
import MianLayout from "@/layout/MianLayout.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Login from "@/views/LoginPages/Login.vue";
import PaymentPage from "@/views/payment/PaymentPage.vue";
import PaymentResponsePage from "@/views/payment/PaymentResponsePage.vue";


const routesTemp = [
    {
        path: "/",
        redirect: "/app"
    },
    {
        name: "MainContainer",
        path: "/app",
        component: MianLayout,
        beforeEnter: (to, from, next) => {
            if (localStorage.accessToken) {
                const userRole = JSON.parse(localStorage.getItem('user'))['role'].toUpperCase();
                if (to.meta?.authorize?.includes(userRole)) {
                    next();
                } else {
                    const defaultPath = Navigations.find(item => {
                        if (item.main && item.meta.authorize.includes(userRole)) {
                            return item;
                        }
                    })
                    if (defaultPath) {
                        next({name: defaultPath.name});
                    } else {
                        console.log("Sizda ushbu sahifaga kirish huquqi mavjud emas");
                    }
                }
            } else {
                next("/login");
            }
        },
        children: Navigations
    },
    {
        name: "login",
        path: "/login",
        component: Login,
        beforeEnter: (to, from, next) => {
            if (localStorage.accessToken) {
                next({path: "/"});
            } else {
                next();
            }
        }
    },
    {
        name: "payment",
        path: "/payment",
        component: PaymentPage
    },
    {
        name: "success",
        path: "/payment/success",
        component: PaymentResponsePage
    },
    {
        name: "error",
        path: "/payment/error",
        component: PaymentResponsePage
    },
    {
        name: "PageNotFound",
        path: "/:pathMatch(.*)*",
        component: PageNotFound
    }
];

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: routesTemp
})
export default router
