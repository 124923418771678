<template>
  <div class="Main" style="overflow-y: scroll">
    <div class="tableOne">
      <div class="search_and_profil add_search_and_profile">
        <div class="profile_box">
          <div class="cercle">
            <b-icon
                icon="circle-fill"
                animation="throb"
                font-scale="2"
                style="width: 20px; height: 20px"
            ></b-icon>
          </div>
          <div class="title">
            <h4>Admin</h4>
          </div>
          <div class="chevron">
            <b-icon
                icon="chevron-down"
                style="width: 15px; height: 15px"
            ></b-icon>
          </div>
        </div>
      </div>

      <div class="title_boxx">
        <router-link to="/app/sobity" class="back_link">
          <div class="back_icon">
            <b-icon
                icon="arrow-left"
                style="width: 25px; height: 25px; color: #2f3641"
            ></b-icon>
          </div>
          <h2>Изменть События</h2>
        </router-link>
      </div>

      <div class="table_box shablon_in">
        <div class="Form_box">
          <!-- Form box -->
          <div class="form_box form_settengs">
            <b-form @submit.prevent="edit" class="w-100">
              <div class="form_in formIn">
                <!-- Event Title -->
                <div class="boxx">
                  <label class="title" for="user_name">Название события</label>
                  <b-form-input
                      class="inputt"
                      id="user_name"
                      required
                      v-model="event_title"
                  />
                </div>
                <!-- Data-->
                <div class="boxx">
                  <label class="title" for="Position">Дата</label>
                  <input
                      class="datePick"
                      type="date"
                      id="Position"
                      required
                      v-model="created_at"
                  />
                </div>
                <div class="boxx">
                  <label class="title" for="Position">Время начало</label>
                  <input
                      type="time"
                      class="datePick"
                      id="Position"
                      required
                      v-model="timeStart"
                  />
                </div>
                <!-- Organisation -->
                <div class="boxx">
                  <label class="title" for="end_data">Дата окончания</label>
                  <input
                      type="date"
                      class="datePick"
                      id="end_data"
                      required
                      v-model="end_data"
                  />
                </div>
                <div class="boxx">
                  <label class="title" for="Position">Время окончания</label>
                  <input
                      type="time"
                      class="datePick"
                      id="Position"
                      required
                      v-model="timeEnd"
                  />
                </div>

                <!-- Link Fild-->
                <div class="boxx">
                  <label class="title" for="link">Ссылка</label>
                  <b-form-input
                      class="inputt"
                      id="link"
                      required
                      v-model="link"
                  />
                </div>
                <div class="boxx">
                  <label class="title" for="link">Адрес</label>
                  <b-form-input
                      class="inputt"
                      id="link"
                      required
                      v-model="address"
                  />
                </div>
                <!-- Btn box -->
                <div class="btn_box">
                  <button class="btn_box" type="submit">Сохранить</button>
                </div>
              </div>
            </b-form>
          </div>
          <!-- img ind Link box
            <div class="form_cart cart_settings">
                <div>
                  <label>wekwdk</label>
                </div>
                <div class="copy_link addSobityy">
                    <div class="input_box">
                        <input type="text" v-model="copy_link">
                    </div>
                    <div class="btns add_btns">
                        <button @click="copy_linkk" class="btn_two add_two">Копировать</button>
                    </div>
                </div>
            </div> -->
        </div>
      </div>
    </div>
    <b-overlay v-if="busy" no-wrap></b-overlay>
    <notes ref="message"></notes>
  </div>
</template>

<script>
// import {mapMutations, mapGetters, mapState} from 'vuex';
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import {instance} from "@/utils/composables";

export default {
  components: {
    // ToastificationContent,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      // Form information
      event_title: "",
      created_at: null,
      organization: null,
      end_data: "",
      link: "",
      address: "",
      timeStart: "",
      timeEnd: "",
      busy: false,
      id: this.$route.params.id,
      copy_link: "https://event.net",
    };
  },
  created() {
    if (this.$route.params.id) {
      this.$store.commit("setCurrentId", this.$route.params.id);
    }
  },
  async mounted() {
    try {
      this.busy = true;
      const response = await instance.get(
          `/event/${this.$route.params.id}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }
      );
      this.end_data = response.data.end_date.split(" ")[0];
      this.timeEnd = response.data.end_date.split(" ")[1];
      this.created_at = response.data.start_date.split(" ")[0];
      this.timeStart = response.data.start_date.split(" ")[1];
      this.event_title = response.data.title.ru;
      this.link = response.data.link
          ? response.data.link
          : "https://event.net";
      this.address = response.data.address ? response.data.address : "";
      this.organization = response.data.organization;
    } catch (err) {
      this.$toast.error(err);
    } finally {
      this.busy = false;
    }
  },
  methods: {
    copy_linkk() {
      console.log("Link Copid");
      this.$refs.message.success("Ссылка скопирована");
    },
    async edit() {
      try {
        this.busy = true;
        let formData = new FormData();
        let title = {
          uz: this.event_title,
          ru: this.event_title,
          en: this.event_title,
        };
        formData.append('start_date', `${this.created_at} ${this.timeStart}`);
        formData.append('end_date', `${this.end_data} ${this.timeEnd}`);
        formData.append('organization', this.organization);
        formData.append('address', this.address);
        formData.append('link', this.link);
        formData.append('title', JSON.stringify(title));
        const response = await instance.patch(
            `/event/${this.$route.params.id}/`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.accessToken}`,
              },
            }
        );
        this.$toast.success("Событие успешно изменено");
        await this.$router.push("/app/sobity");
      } catch (err) {
        this.$toast.error(err);
      } finally {
        this.busy = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #f8f7ff;
}

.datePick {
  font-size: 16px !important;
  color: #2f3641;
  border: 1px solid #ced4da;
  padding: 13px 10px !important;
  border-radius: 15px;
}
</style>
