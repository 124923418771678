<template>
  <div class="Main" style="overflow-y: scroll">
    <div class="tableOne">
      <div class="search_and_profil"></div>

      <div class="title_boxx">
        <div>
          <h2>Настройки</h2>
        </div>
      </div>

      <form @submit.prevent="save" class="table_box shablon_in">
        <div><h4>Шаблоны электронных писем</h4></div>
        <div class="emailBoult">
          <div>
            <div>Email</div>
            <div>
              <input placeholder="email" required v-model="email" type="text"/>
            </div>
          </div>
          <div>
            <div>Password</div>
            <div>
              <b-input-group>
                <input
                  required
                  v-model="password"
                  :type="showPassword?'text':'password'"
                  style="padding-left: 20px!important; border-top-right-radius: 0; border-bottom-right-radius: 0"
                />
                <b-button size="sm" variant="outline-info" class="custom-wrapper-btn"
                          @click="showPassword =!showPassword">
                  <b-icon font-scale="2" :icon="showPassword?'eye-slash-fill':'eye-fill'"
                          variant="secondary"/>
                </b-button>
              </b-input-group>
            </div>
          </div>
          <div>
            <div>Host</div>
            <div v-if="hosts && hosts.length > 0">
              <select required v-model="hostId">
                <template v-for="(host, index) in hosts">
                  <template v-if="index === 0">
                    <option :value="host.id" selected>{{ host.name }}</option>
                  </template>
                  <template v-else>
                    <option :value="host.id">{{ host.name }}</option>
                  </template>
                </template>
              </select>
            </div>
          </div>
        </div>

        <div class="texts">
          <div>
            <label for="error">Текст, ожидающий рассмотрения</label>
            <div class="textFields">
              <VueEditor placeholder="Текст, ожидающий рассмотрения..." v-model="pending_text"/>
            </div>
          </div>

          <div>
            <label for="success">Текст при успешной регистрации</label>
            <div class="textFields">
              <!-- <template v-if="data.success_text"> -->
              <VueEditor placeholder="Текст при успешной регистрации..." v-model="data.success_text"/>
              <!-- </template> -->
            </div>
          </div>
        </div>

        <div class="texts">
          <div>
            <label for="error">Текст платежа</label>
            <div class="textFields">
              <VueEditor placeholder="Текст платежа..." v-model="payment_text"/>
            </div>
          </div>

          <div>
            <label for="error">Текст отмены</label>
            <div class="textFields">
              <VueEditor placeholder="Текст отмены..." v-model="cancel_text"/>
            </div>
          </div>
        </div>

        <div class="texts">
          <div>
            <label for="error">Текст приглашения</label>
            <div class="textFields">
              <VueEditor placeholder="Текст приглашения..." v-model="invitation_text"/>
            </div>
          </div>
        </div>

        <div class="my-4">
          <h5>Все доступные переменные (CASE SENSITIVE!!!):</h5>
          <b-button
            v-for="formVariable in formVariables"
            @click="copyVariable(formVariable)"
            variant="outline-primary" class="m-1 p-2"> {{ formVariable }}
          </b-button>
        </div>
        <button type="submit" class="save">Сохранить</button>
      </form>
    </div>
    <b-overlay :show="busy" no-wrap></b-overlay>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      data: {},
      email: "",
      password: null,
      busy: true,
      hosts: null,
      hostId: null,
      invitation_text: "",
      pending_text: "",
      payment_text: "",
      cancel_text: "",
      success_text: "",
      showPassword: false,
      formVariables: [
        "{FULL_NAME}",
        "{USER_LOGIN}",
        "{PUBLIC_ID}",
        "{USER_PASSWORD}",
        "{USER_ACCOUNT_LINK}",
        "{QR}",
        "{PAYMENT_LINK}",
        "{FIRST_NAME_WITH_PREFIX}",
        "{FIRST_NAME",
        "{LAST_NAME}",
        "{PREFIX}",
        "{CITIZENSHIP}",
        "{EMAIL}",
        "{PHONE_NUMBER}",
        "{TOUR}",
        "{TRAVELERS_NUMBER}",
        "{COMMENT}",
      ]
    };
  },
  methods: {
    onFileChange(e) {
      console.log("Jamshid qara", e);
      console.log("this.file", this.file);
      //   const file = e.target.files[0];
    },
    async save() {
      try {
        this.busy = true;

        let formData = new FormData();

        formData.append("success_text", this.data.success_text);
        formData.append("email_username", this.email);
        formData.append("email_password", this.password);
        formData.append("email_host", this.hostId);
        formData.append("invitation_text", this.invitation_text);
        formData.append("pending_text", this.pending_text);
        formData.append("payment_text", this.payment_text);
        formData.append("cancel_text", this.cancel_text);

        const allData = {
          id: this.data.id,
          formData,
        };

        await this.$store.dispatch("saveConfig", allData);
        this.$toast.success("Настройки для этого события успешно изменены");
        // await this.$router.push(`/app/sobity/`);
      } catch (err) {
        this.$toast.error(err);
      } finally {
        this.busy = false;
        window.location.reload();
      }
    },
    async copyVariable(value) {
      // console.log(value);
      try {
        await navigator.clipboard.writeText(value);
        this.$toast.success(`${value} copied!`);
      } catch (e) {
        console.error(e);
        this.$toast.error('Cannot copy!');
      }
    }
  },
  async mounted() {
    await this.$store.dispatch("getHosts");
    // this.hosts = this.$store.state.hosts;
    // this.hosts = this.$store.state.hosts;
    this.hosts = this.$store.state.hosts.results.map((host) => {
      return {id: host.id, name: host.name};
    });
    // console.log(this.hosts);
    try {
      this.busy = true;
      await this.$store.dispatch("getConfig", localStorage.getItem("event"));
    } catch (er) {
      console.log(er);
    } finally {
      this.busy = false;
    }
    this.data = this.$store.state.config;
    this.invitation_text = this.data.invitation_text;
    this.pending_text = this.data.pending_text;
    if (this.data.payment_text) {
      this.payment_text = this.data.payment_text;
    }
    if (this.data.cancel_text) {
      this.cancel_text = this.data.cancel_text;
    }
    if (this.data.email_host) {
      this.hostId = this.data.email_host;
    }
    if (this.data.success_text === null) {
      this.success_text = "";
    }
    if (this.data.email_username) {
      this.email = this.data.email_username;
    }
    if (this.data.password) {
      this.password = this.data.password;
    }

    if (this.data.email) {
      this.email = this.data.email;
    }
    if (this.data.email_password) {
      this.password = this.data.email_password;
    }
  },
};
</script>

<style scoped lang="scss">
.emailBoult {
  margin: 10px auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 33%;

    input,
    select {
      padding: 5px 5px 5px 10px;
      border-radius: 5px;
      border: 1px solid #c9c9c9;
      width: 60%;
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
}

.texts {
  label {
    margin-top: 20px;
  }
}

.textFields {
  margin: 10px;

  input,
  textarea {
    padding: 10px 0 0 10px;
    width: 90%;
    height: 200px;
    border: 1px solid #999999;
    border-radius: 10px;
  }
}

.save {
  margin-top: 20px;
  padding: 10px 40px;
  border: none;
  border-radius: 10px;
  background: #354b9c;
  color: white;
}

.custom-wrapper-btn {
  box-shadow: none;
  border: 1px solid #ced4da !important;
  border-radius: 0 5px 5px 0;
  text-align: center;
  cursor: pointer;
}
</style>
