<template>
  <div class="main">
    <div class="page-header">
      <h2 class="title">Personal information</h2>
      <div class="ations">

      </div>
    </div>
    <div class="page-info">
      <b-button v-if="userInfo?.payment_status==='paid'" class="download-invoice"
                @click="onClickDownloadInvoice"
                variant="primary">
        <i class='bx bx-receipt' style="font-size:20px"></i>
        Download invoice
      </b-button>
      <b-row>
        <b-col style="margin: 0 auto" xs="12" sm="10" md="8" lg="10" xl="4">
          <b-card class="custom-card p-3">
            <div class="avatar">
              <b-avatar :src="userInfo.image" variant="primary" size="10rem"></b-avatar>
              <span>{{
                  userInfo.first_name && userInfo.last_name ? userInfo.first_name + ' ' + userInfo.last_name : '____'
                }}</span>
            </div>
            <div class="user-info">
              <div class="user-info-row">
                <h6>Birth date: </h6>
                {{ userInfo.birth_date ? userInfo.birth_date : '______' }}
              </div>
              <div class="user-info-row">
                <h6>Gender: </h6>
                <b-form-tag style="font-weight: bold;" disabled variant="primary">
                  {{ userInfo.gender === "M" ? 'MALE' : "FEMALE" }}
                </b-form-tag>
              </div>
              <div class="user-info-row">
                <h6>Email: </h6>
                {{ userInfo.email ? userInfo.email : '______' }}
              </div>
              <div class="user-info-row">
                <h6>Position: </h6>
                {{ userInfo.position ? userInfo.position : '______' }}
              </div>
              <div class="user-info-row">
                <h6>Organization: </h6>
                {{ userInfo.organization ? userInfo.organization : '______' }}
              </div>
              <div class="user-info-row">
                <h6>Passport image: </h6>
                <div class="passport-wrapper">
                  <b-avatar
                      :src="userInfo.passport_image?userInfo.passport_image:'https://education-nationale.gouv.ga/ministere/img/default-image.png'"
                      size="4rem" square>
                  </b-avatar>
                  <span class="fullscreen-icon" @click="showPassport=true">
                    <b-icon icon="arrows-fullscreen"></b-icon>
                  </span>
                  <div class="passport-mask">
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-modal centered
             size="md"
             content-class="passport-modal"
             title="Passport image:"
             hide-footer
             v-model="showPassport">
      <img
          :src="userInfo.passport_image?userInfo.passport_image:'https://education-nationale.gouv.ga/ministere/img/default-image.png'"
          alt="Image" class="custom-image">
    </b-modal>
    <b-overlay :show="loading" no-wrap></b-overlay>
  </div>
</template>

<script>
import {instance} from "@/utils/composables";

export default {
  name: "UserInfo",
  data() {
    return {
      loading: true,
      userInfo: {},
      showPassport: false
    }
  },
  methods: {
    onClickDownloadInvoice() {
      this.loading = true
      instance.get('/user/payment-invoice/', {
        headers: {
          Authorization: "Bearer " + localStorage.accessToken,
        },
        responseType: "arraybuffer"
      })
          .then((response) => {

            let blob = new Blob([response.data], {type: "application/doc"});

            let url = window.URL || window.webkitURL;
            const link = url.createObjectURL(blob);
            let a = document.createElement("a");
            a.setAttribute("download", `Invoice-${this.userInfo?.public_id}.docx`);
            a.setAttribute("href", link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch(({response}) => {
            this.$toast.error(response.data.message)
          })
          .finally(() => {
            this.loading = false
          })
    },
    getUserInfo() {
      instance.get('/user/me/', {headers: {Authorization: "Bearer " + localStorage.accessToken}})
          .then(({data}) => {
            this.userInfo = data
          })
          .catch(({response}) => {
            this.$toast.error(response.data.message)
          })
          .finally(() => {
            this.loading = false
          })
    }
  },
  mounted() {
    this.getUserInfo()
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 100%;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECEFF8;
}

.custom-card .avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-card .avatar > span {
  margin-top: 10px;
  font-weight: 600;
  color: #393c40;
  margin-bottom: 5px;
}

.user-info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px dashed #d4e8ec;
}

.user-info-row > h6 {
  margin-bottom: 0;
  color: #266c7b;
  font-weight: 500;
}

.user-info-row > span {
  font-weight: 400;
  font-size: 14px;
}

.custom-image {
  /*height: 450px;*/
  /*max-width: 600px;*/
  width: 100%;
  object-fit: cover;
}

.passport-wrapper {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.passport-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.fullscreen-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 22px;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.passport-wrapper:hover .passport-mask {
  opacity: 0.4;
}

.passport-wrapper:hover .fullscreen-icon {
  opacity: 1;
}

.download-invoice {
  max-width: 220px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 5px
}
</style>