<template>
  <router-view/>
</template>
<script>

export default {
  name: "App"
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.modal-dialog .modal-content {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
}

.modal-dialog .modal-content .modal-header {
  display: none;
}

.modal-dialog .modal-content .modal-footer {
  flex-direction: column-reverse;
}

.modal-dialog .modal-content .modal-footer > button {
  outline: none;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
  background: #FA6D71;
  border: none;
  color: #fff !important;
  font-size: 14px;
  box-shadow: none;
  opacity: 0.8;
}

.modal-dialog .modal-content .modal-footer > button:first-child {
  background-color: #5D4EC0 !important;
}

*::-webkit-scrollbar {
  background-color: #fff;
  width: 15px;
}

*::-webkit-scrollbar-track {
  background-color: #fff;

}

*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #354B9C;
  border-radius: 16px;
  border: 5px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

*::-webkit-scrollbar-button {
  display: none;
}

</style>
