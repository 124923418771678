<template>
  <div class="fixedP">
    <div class="row row_box">
        <div class="col-8 col-sm-7 col-md-6 col-lg-5 col-xl-4">
            <div class="box">
                <div class="title">
                    <h2>
                        Удалить событие 
                    </h2>
                </div>
                <div class="paragrf">
                    <p>
                        Вы уверены, 
                        <br> что хотите удалить
                        <br> это событие
                    </p>
                </div>
                <button @click="delete_funk" class="closee">Удалить</button>
                <button @click="close_funk" class="close_btn">Отмена</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
props: {
    item_id: {
        type:Number,
        default:null
    },
    show_modal: {
      type: Boolean,
      default: null
    },
},
data(){
    return{
        modal_show:this.show_modal,
        delete_id:this.item_id,
        Name: "",
        Id:"",
        Duration: "",
        Course_name: "",
        Created_at:""
    }
},
methods: {
    // ...mapMutations(["addUser"]),
    delete_funk(){
        console.log("Modal form in", this.modal_show)
        console.log("Modal form in", this.delete_id)
    },
    close_funk(){
        this.modal_show=!this.modal_show
    },
    AddUser(){
        let user={
            Name:this.Name,
            Id:this.Id,
            Duration: this.Duration,
            Course_name: this.Course_name,
            Created_at: this.Created_at
        }
        this.addUser(user)
    },
}
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
</style>