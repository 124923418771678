<template>
  <div class="main-wrapper">
    <AdminLayout v-if="isAdmin"/>
    <UserLayout v-else/>
  </div>
</template>

<script>
import {mdbIcon} from 'mdbvue';
import {Navigations} from "@/router/navigations";
import AdminLayout from "@/layout/AdminLayout.vue";
import UserLayout from "@/layout/UserLayout.vue";

export default {
  components: {
    UserLayout,
    AdminLayout,
    mdbIcon,
  },
  data() {
    return {}
  },
  computed: {
    isAdmin() {
      return JSON.parse(localStorage.getItem('user'))['role'] !== 'user'
    }
  },
  methods: {}
};
</script>

<style lang="scss">
</style>