<template>
  <div class="user-layout">
    <user-header/>
    <user-menu class="user-menu"/>
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>xs

<script>
import UserHeader from "@/components/header/UserHeader.vue";
import UserMenu from "@/components/header/UserMenu.vue";

export default {
  name: "UserLayout",
  components: {UserMenu, UserHeader}
}
</script>

<style scoped lang="scss">
.user-layout {
  height: 100vh;
}

.main-content {
  height: calc(100vh - 80px);
  overflow: auto;
  padding: 20px;
  background-color: #f9f9fb;
}

@media (min-width: 900px) {
  .user-menu {
    display: none;
  }
}

</style>