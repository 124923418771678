<template>
  <div class="main">
    <div class="page-header">
      <div class="text">
        Please select the sessions you would like to participate in
      </div>
    </div>
    <h4 class="title">{{ sessionData.title }}</h4>
    <div class="tableOne">
      <div class="table_box">

        <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase"
            content-class="mt-3"
        >
          <b-tab v-for="(day,index) in sessionData.days" :title="'DAY ' + (index+1) +' | '+day.date"
                 :active="index ===0">
            <div class="sessions" v-for="(session,idx) in day.sessions" :key="idx">
              <h4 class="session-title">{{ session.title }}</h4>
              <div class="agenda">
                <div class="agenda-items" v-for="agenda in session.times" :key="agenda.id">
                  <div>
                    <h4 class="time-title">{{ agenda.start_time + ' - ' + agenda.end_time }}</h4>
                    <i v-if="agenda.in_parallel" class="in-parallel">(in parallel)</i>
                  </div>
                  <div class="agenda-item">
                    <b-form-group v-if="agenda.in_parallel && agenda.is_radio" label="Individual radios"
                                  v-slot="{ ariaDescribedby }">
                      <div class="agenda-item-row" v-for="(items) in agenda.items" :key="items.id">
                        <b-form-radio v-model="agenda.selected" :value="items.id">
                          <i v-if="items.type_text" class="type-text">{{ items.type_text + ":" }}</i>
                          <h4 class="time-title">{{ items.title }}</h4>
                        </b-form-radio>
                      </div>
                    </b-form-group>
                    <div v-else class="agenda-item-row" v-for="(items) in agenda.items" :key="items.id">
                      <i v-if="items.type_text" class="type-text">{{ items.type_text + ":" }}</i>
                      <h4 class="time-title">{{ items.title }}</h4>
                    </div>
                    <b-button v-if="agenda.in_parallel && agenda.is_radio" size="sm" @click="onClickSaveBtn(agenda.id)" variant="primary"
                              style="max-width: 90px; width: 100%;margin: 0 auto; font-weight: bold">Save
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <b-overlay :show="loading" no-wrap></b-overlay>
    </div>
  </div>
</template>

<script>
import {instance} from "@/utils/composables";
import store from "../../store";

export default {
  name: "UserSession",
  computed: {
    store() {
      return store
    },
    userId() {
      return JSON.parse(localStorage.getItem('user')).id
    }
  },
  data() {
    return {
      sessionData: {},
      loading: false,
      selected: null,
    }
  },
  methods: {
    onClickSaveBtn(timesId) {
      let timeTemp;
      this.sessionData.days?.forEach(day => {
        day.sessions?.forEach(session => {
          session.times?.forEach(time => {
            if (time.id === timesId && time.in_parallel) {
              timeTemp = time;
            }
          })
        })
      });
      if (timeTemp && timeTemp.selected) {
        let formData = new FormData();
        formData.append('user', this.userId);
        formData.append('session_time', timeTemp.id);
        formData.append('session_item', timeTemp.selected);
        instance.post("/user/session-item/", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        }).then(() => {
          this.$toast.success("Successfully saved");
          this.getSessions()
        }).catch(({error}) => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.$toast.warning('Please select')
      }
    },
    getSessions() {
      this.loading = true;
      instance.get("/user/event-agenda/", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(({data}) => {
        this.sessionData = data;
      }).catch(({error}) => {
        this.$toast.error(error.response.data.message)
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.getSessions()
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 100%;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECEFF8;
}

.text, .title {
  padding: 5px;
  width: 100%;
  font-weight: 500;
  color: #2F3641;
}

.text {
  background-color: #ffffff;
}

.session-title, .agenda-items {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.time-title {
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
}

.in-parallel {
  font-weight: 300;
}

.agenda-items {
  display: flex;
  margin-top: 10px;
}

.agenda-item-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.type-text {
  font-weight: 400;

}

:deep(.tabs) {
  .nav-link {
    color: #003874;
    font-weight: bolder;
    font-size: 14px;
  }

  .nav-link.active {
    color: #BE9938;
  }
}
</style>