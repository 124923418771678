<template>
  <div class="login_In">
    <div class="container">
      <div class="row row_login_in">
        <div class="col-11 cart_img">
          <!-- <img src="@/assets/iift-logo.svg" alt="png"/> -->
          <!--          <img src="@/assets/Real.svg" alt="png"/>-->
        </div>
        <div class="col-9 col-sm-9 col-md-7 col-lg-5 col-xl-4">
          <div class="form_box" style="">
            <b-form @submit.prevent="LoginFunk" class="w-100">
              <div class="form_in">
                <div class="form_head">
                  <h2>Авторизация</h2>
                </div>
                <!-- Email -->
                <div class="boxx">
                  <!--<label class="title" for="organisation">Email</label>-->
                  <label class="title" for="organisation">Электронная почта</label>
                  <b-form-input
                      class="inputt loginEmail"
                      type="email"
                      id="organisation"
                      required
                      v-model="user_email"
                  />
                </div>

                <!-- User Password -->
                <div class="boxx">
                  <label class="title" for="password">Пароль</label>
                  <b-input-group>
                    <b-form-input
                        id="password"
                        required
                        :type="showPassword?'text':'password'"
                        v-model="password"
                        style="padding-left: 20px!important;"
                    />
                    <b-input-group-append>
                      <b-button size="sm" variant="outline-info" class="custom-wrapper-btn"
                                @click="showPassword =!showPassword">
                        <b-icon font-scale="2" :icon="showPassword?'eye-slash-fill':'eye-fill'"
                                variant="secondary"/>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>

                <!-- Btn box -->
                <div class="btn_box">
                  <button class="btn_box" type="submit">
                    <div class="dot-loading" :class="{'active':busy}">
                      <dots-loader/>
                    </div>
                    <span :class="{'login':busy}">Войти</span>
                  </button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import axios from "axios";
import router from "@/router";
import store from "@/store";
import DotsLoader from "@/components/loader/DotsLoader.vue";

export default {
  components: {
    DotsLoader,
    // ToastificationContent,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },

  data() {
    return {
      user_email: "",
      password: "",
      busy: false,
      showPassword: false
    };
  },

  mounted() {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    this.$store.commit("setToken", {access_token: null, refresh_token: null})
  },

  methods: {
    // Authorisation
    async LoginFunk() {
      this.busy = true;
      const data = JSON.stringify({
        email: this.user_email,
        password: this.password,
      });
      try {
        const res = await this.$api.post('user/token/', data, {
          headers: {
            "Content-Type": "application/json",
          }
        });
        // console.log(res);
        store.commit("setToken", res.data);
        localStorage.setItem("accessToken", res.data['access_token']);
        localStorage.setItem("refreshToken", res.data['refresh_token']);
        await this.$store.dispatch("getDetailails");
      } catch (error) {
        if (error.response?.status === 401) {
          this.$toast.error("Доступ запрещен: неправильный почты или пароль.");
        }
      } finally {
        this.busy = false;
      }
      // axios.post(
      //     "https://api.planerps.uz/api/v1/user/token/",
      //     {
      //       email: this.user_email,
      //       password: this.password,
      //     }
      // ).then(({data}) => {
      //   store.commit("setToken", data);
      //   localStorage.setItem("accessToken", data['access_token']);
      //   localStorage.setItem("refreshToken", data['refresh_token']);
      //   this.$store.dispatch("getDetailails")
      // }).catch((err) => {
      //   this.$toast.error(err.response.data.message)
      // }).finally(() => {
      //   this.busy = false;
      // });
    }
  }
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login_In {
  width: 100vw;
  background: #003874;
}

.dot-loading {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.dot-loading.active {
  opacity: 1;
}

.login {
  opacity: 0;
  transition: opacity 0.2s;
}

.cart_img {
  text-align: center;
}

.custom-wrapper-btn {
  box-shadow: none;
  border: 1px solid #ced4da !important;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  cursor: pointer;
}
</style>
