<template>
  <div class="Main" style="overflow-y: scroll">
    <div class="tableOne">
      <div class="search_and_profil">
        <!-- Search boxx -->
        <div class="input_search">
          <label for="inputt" class="iconn">
            <b-icon
              class="icon"
              icon="search"
              style="width: 20px; height: 20px"
            ></b-icon>
          </label>
          <div class="input_box">
            <input
              class="imput_in"
              type="search"
              id="inputt"
              v-model="filter"
              @input="onInputSearch"
              placeholder="Поиск"
            />
          </div>
        </div>
      </div>

      <!-- Main btn for add -->
      <div class="title_boxx">
        <div>
          <h2>События</h2>
        </div>
        <router-link to="/app/addSobity" class="add_btn">
          <button>
            <span
            ><b-icon
              class="icon"
              icon="plus"
              style="width: 20px; height: 20px"
            ></b-icon></span
            >Создать событие
          </button>
        </router-link>
      </div>

      <!-- Table box Start -->
      <div class="table_box">
        <div class="table_title">
          <h3>Текущие События</h3>
        </div>
        <b-table
          class="table_in"
          id="my-table"
          :table-variant="tableVariant"
          show-empty
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          striped
          hover
          responsive
          @row-clicked="clicked"
        >
          <template #cell(title)="data">
            <div>
              {{ data.item.title ? data.item.title : "-" }}
            </div>
          </template>
          <template #cell(action)="data">
            <div class="boxx">
              <router-link :to="'/app/editSobity/' + data.item.id">
                <div class="edit_box">
                  <b-icon
                    icon="pencil"
                    style="width: 15px; height: 15px; color: #fff"
                  ></b-icon>
                </div>
              </router-link>
              <b-button
                variant="danger"
                class="btn-icon"
                @click="toggleModal(data.item.id)"
              >
                <b-icon
                  icon="trash"
                  style="width: 15px; height: 15px; color: #fff"
                ></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>

        <b-overlay :show="busy" no-wrap></b-overlay>
        <div class="box_pagination" v-if="!busy">
          <div>
            Все: <strong> {{ totalRows }}</strong>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            @change="onChangePagination"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
    <!-- Modal form start -->
    <div v-if="show_modal" class="fixedP">
      <div class="row row_box">
        <div class="col-8 col-sm-7 col-md-6 col-lg-5 col-xl-4">
          <div class="box">
            <div class="title">
              <h2>Удалить событие</h2>
            </div>
            <div class="paragrf">
              <p>
                Вы уверены,
                <br/>
                что хотите удалить <br/>
                это событие
              </p>
            </div>
            <button @click="deleteItemFun" class="closee">Удалить</button>
            <button @click="close_funk" class="close_btn">Отмена</button>
          </div>
        </div>
      </div>
    </div>
    <notes ref="message"></notes>
  </div>
</template>

<script>
// import Modal from '@/components/BootstrapComp/ModalForm.vue'
// import {mapMutations, mapGetters, mapState} from 'vuex';
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
// import ToastificationContent from "@/components/ToastificationContent.vue";
export default {
  components: {
    // Modal,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      Item_id: null,
      show_modal: false,
      tableVariant: "light",
      color: "#354B9C",
      selectedCategory: {},
      busy: true,
      items: [],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "title.ru",
          label: "Название",
          class: "text-center customHeader",
          sortable: true,
        },
        {
          key: "organization",
          label: "Организация",
          sortable: true,
        },
        {
          key: "status.ru",
          label: "Статус События ",
          sortable: true,
        },
        {
          key: "participants_count",
          label: "Заявки",
          sortable: true,
        },
        {
          key: "visits_count",
          label: "Посещение",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Дата Начала",
          sortable: true,
        },
        {
          key: "end_date",
          label: "Дата Окончания",
          sortable: true,
        },
        {
          key: "action",
          label: "Действие",
        },
      ],
      perPage: 10,
      totalItems: null,
      active: true,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: "",
      filterOn: [],
      totalRows: 1,
      deletingItem: null,
    };
  },
  computed: {
    currentPage() {
      return this.$route.query.page ? this.$route.query.page : 1;
    },
  },
  methods: {
    onInputSearch() {
      clearTimeout(this.timeout);
      if (this.$route.query.page !== "1") {
        this.$router.replace({query: {page: "1"}});
      }
      this.timeout = setTimeout(() => this.get(), 1000);
    },
    onChangePagination(val) {
      this.$router.replace({query: {page: val}}).then(() => {
        this.get();
      });
    },
    delete_funk() {
      this.items.splice(this.Item_id - 1, 1);
      this.show_modal = !this.show_modal;
      this.$refs.message.success("Delete successfully");
    },
    close_funk() {
      this.show_modal = !this.show_modal;
    },
    clicked(item) {
      localStorage.setItem("event", item.id);
      // this.$router.push(`/app/users/`);
      this.$router.push(`/app/information`);
    },
    toggleModal(p) {
      this.deletingItem = p;
      this.show_modal = true;
    },
    // Delete Items
    async deleteItemFun() {
      try {
        this.busy = true;
        const id = this.deletingItem;
        await this.$store.dispatch("deleteItem", id);
      } catch (err) {
        console.error(err);
      } finally {
        this.busy = false;
        this.deleteItem = null;
        this.show_modal = false;
        window.location.reload();
      }
    },

    async get() {
      try {
        let page = this.$route.query.page ? this.$route.query.page : 1;
        let payloadData = {
          page,
          page_size: this.perPage,
          search: this.filter,
        };
        await this.$store.dispatch("getEvents", payloadData);
        this.items = this.$store.state.events?.results;
        this.totalRows = this.$store.state.events?.count;
      } catch (error) {
        console.log(error);
      } finally {
        this.busy = false;
      }
    },
    // getInformation() {
    //   instance.get("/event/countries/").then(({ data }) => {
    //     // data.forEach((country) => {
    //     //   this.countryOptions += `<option value="${country.id}">${country.title.en}</option>`;
    //     // });
    //   });
    // },
  },

  mounted() {
    this.get();
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #f8f7ff;
}

.active {
  background: #4285f4;
  color: white;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%),
  0 2px 10px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.box {
  position: relative;
  z-index: 999999;
}

.row,
.row_box {
  position: relative;
  z-index: 9999;
}

.fixedP {
  z-index: 9999;
}

.table_in {
  thead {
    tr {
      th {
        background: #4285f4 !important;
        color: white !important;
      }
    }
  }
}
</style>
