<template>
  <div class="Main" style="overflow-y:scroll">
    <div class="tableOne">
      <div class="search_and_profil add_search_and_profile">

        <div class="profile_box" v-if="!!isAdmin.first_name || !!isAdmin.last_name">
          <div class="cercle">
            <b-icon icon="circle-fill" animation="throb" font-scale="2" style="width: 20px; height: 20px;"></b-icon>
          </div>
          <div class="title">
            <h4>{{ isAdmin.first_name + " " + isAdmin.last_name }}</h4>
          </div>
          <div class="chevron">
            <b-icon icon="chevron-down" style="width: 15px; height: 15px;"></b-icon>
          </div>
        </div>

      </div>

      <div class="title_boxx ">
        <div @click="() => {$router.go(-1)}" style="cursor: pointer;" class="back_link">
          <div class="back_icon">
            <b-icon icon="arrow-left" style="width: 25px; height: 25px; color:#2F3641"></b-icon>
          </div>
          <h2>Изменить пользователя</h2>
        </div>
      </div>

      <div class="table_boxx">
        <div class="row Form_box_row">
          <div class="col-lg-8">
            <b-form class="b_form" @submit.prevent="EditUser">
              <div class="inputs_one">
                <!-- User name -->
                <div class="boxx">
                  <label class="title" for="user_name">Имя</label>
                  <b-form-input
                      class="inputt"
                      id="user_name"
                      required
                      v-model="name"
                  />
                </div>

                <!-- Status -->
                <div class="boxx">
                  <label class="title" for="role">Статус</label>
                  <select
                      class="select_inputt"
                      id="role"
                      required
                      v-model="role"
                  >
                    <option v-for="option in roles" :value="option.value">{{ option.text }}</option>
                  </select>
                </div>

                <!-- Save Btn -->
                <div class="btn_box">
                  <button class="btn_box" type="submit">Сохранить</button>
                </div>

              </div>
              <div class="inputs_one">
                <!-- Last Name -->
                <div class="boxx">
                  <label class="title" for="user_name">Фамилия</label>
                  <b-form-input
                      class="inputt"
                      id="user_name"
                      required
                      v-model="last_name"
                  />
                </div>
              </div>
            </b-form>
          </div>
          <b-overlay :show="busy" no-wrap></b-overlay>
        </div>
      </div>
    </div>
    <notes ref="message"></notes>
  </div>
</template>

<script>
// import {mapMutations, mapGetters, mapState} from 'vuex';
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  components: {
    // ToastificationContent,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      // Form information
      name: "",
      last_name: "",
      id: null,
      role: "",
      options: [
        {
          value: "admin", text: 'Админ'
        },
        {
          value: "customer", text: 'Клиент'
        },
        {
          value: "staff", text: 'Персонал'
        }
      ],
      busy: false,
    };
  },
  computed: {
    isAdmin() {
      return JSON.parse(localStorage.getItem('user'))
    },
    roles() {
      if (this.isAdmin.role !== 'staff') {
        return [
          {
            value: "admin", text: 'Админ'
          },
          {
            value: "customer", text: 'Клиент'
          },
          {
            value: "staff", text: 'Персонал'
          }
        ]
      } else {
        return this.options
      }
    }
  },
  methods: {
    async EditUser() {
      try {
        this.busy = true
        const id = this.id;

        let formData = new FormData();
        formData.append('first_name', this.name);
        formData.append('last_name', this.last_name);
        formData.append('role', this.role);

        const payload = {id, formData}
        await this.$store.dispatch("patchAdmin", payload)
        this.$router.go(-1)
      } catch (err) {
        console.log(err);
        await this.$router.push("/")
        alert(err);
      } finally {
        this.busy = false
      }
    },
  },
  async mounted() {
    try {
      this.busy = true
      const id = this.$route.params.id
      await this.$store.dispatch("getExactUser", id)
      this.name = this.$store.state.exactAdmin.first_name
      this.last_name = this.$store.state.exactAdmin.last_name
      this.role = this.$store.state.exactAdmin.role
      this.id = this.$store.state.exactAdmin.id
    } catch (err) {
      console.log(err);
    } finally {
      this.busy = false
    }
  }
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #F8F7FF;
}

#role {
  color: #2F3641;
}

.inputt {
  color: #2F3641 !important;
}

.select_inputt {
  width: 100%;
  line-height: 20px !important;
  font-size: 16px !important;
  color: #2F3641;
  padding: 13px !important;
  border-radius: 15px;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
</style>