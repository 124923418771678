<template>
  <div class="main">
    <div class="currency">
      <b-overlay :show="currencyLoader" rounded="lg" opacity="0.2">
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner small type="grow" variant="secondary"></b-spinner>
            <b-spinner type="grow" variant="dark"></b-spinner>
            <b-spinner small type="grow" variant="secondary"></b-spinner>
            <span class="sr-only">Please wait...</span>
          </div>
        </template>
        <h5 class="m-2" v-for="item in currency">
          <span class="title">{{ item.Ccy }}</span
          >: {{ item.Rate }}
        </h5>
      </b-overlay>
    </div>

    <div class="page-header">
      <div class="logo-wrapper">
        <!-- <img src="@/assets/Real.svg" alt="LOGO"/> -->
        <img
          src="@/assets/Logo.png"
          alt="LOGO"
          style="width: 200px; height: 200px"
        />
      </div>
      <h5 class="title mt-1">Payment form</h5>
    </div>
    <div class="page-info">
      <b-overlay :show="loading">
        <b-form class="form" @submit.prevent.stop="onSubmit">
          <b-form-group
            id="input-group-1"
            label="Amount of money to be paid"
            label-for="currency"
          >
            <p
              style="
                background-color: #e4e4e4;
                padding: 5px 15px;
                border-radius: 15px;
              "
            >
              {{ form.amount.toLocaleString("ru-RU") + " " + form.currency }}
            </p>
          </b-form-group>
          <b-form-group
            label="Select currency type"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              @change="onChangeCurrency"
              v-model="form.currency"
            >
              <b-form-radio
                v-model="form.currency"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="USD"
                >USD
              </b-form-radio>
              <b-form-radio
                v-model="form.currency"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="UZS"
                >UZS
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="!form.public_id"
            class="custom-button"
            >Request for payment
          </b-button>
        </b-form>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PaymentPage",
  data() {
    return {
      loading: false,
      currencyLoader: false,
      apiUrl: process.env.VUE_APP_API_URL,
      clientDomain: process.env.VUE_APP_CLIENT_DOMAIN,
      form: {
        public_id: null,
        amount: 1,
        currency: "USD",
      },
      currencies: [
        { text: "USD", value: "USD" },
        { text: "UZS", value: "UZS" },
      ],
      currency: [],
    };
  },
  methods: {
    onChangeCurrency() {
      let temp = this.currency.find((item) => item.Ccy === "USD");

      if (this.form.currency === "UZS") {
        this.form.amount = this.form.amount * temp.Rate;
      } else {
        this.form.amount = this.form.amount / temp.Rate;
      }
    },
    getCbuCurrency() {
      this.currencyLoader = true;
      axios
        .get(`${this.apiUrl}/cbu-currency/`)
        .then(({ data }) => {
          data.forEach((currency) => {
            if (
              currency.Ccy === "USD" ||
              currency.Ccy === "UZS" ||
              currency.Ccy === "GBP" ||
              currency.Ccy === "EUR"
            ) {
              this.currency.push(currency);
            }
          });
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.message);
        })
        .finally(() => {
          this.currencyLoader = false;
        });
    },
    onSubmit() {
      this.loading = true;
      const url = `${this.apiUrl}/payment/v2/payment/create/`;
      axios
        .post(url, this.form)
        .then(({ data }) => {
          window.location.href = data["paymentUrl"];
        })
        .catch(({ response }) => {
          // console.log(response)
          if (response.data.message) {
            this.$toast.error(response.data.message[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getCbuCurrency();
    if (this.$route.query.public_id) {
      this.form.public_id = this.$route.query.public_id;
      this.form.error_redirect_url = `${this.clientDomain}/payment/error?public_id=${this.$route.query.public_id}`;
      this.form.success_redirect_url = `${this.clientDomain}/payment/success`;
    }
  },
};
</script>

<style scoped lang="scss">
.main {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fafaff;
  position: relative;
  overflow: hidden;
}

.currency {
  position: absolute;
  top: 15px;

  div {
    display: flex;

    .title {
      color: #2a6dd9;
      font-weight: 400;
    }
  }
}

.page-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .logo-wrapper {
    img {
      height: 50px;
    }
  }
}

.page-info {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

.form {
  padding: 40px;
  box-shadow: 0 16px 24px rgba(24, 25, 26, 0.1);
  border-radius: 24px;
}

.custom-input {
  padding-right: 10px !important;
  padding-left: 10px !important;
  margin-top: 10px;
}

.custom-button {
  width: 100%;
  height: 45px;
  border-radius: 15px;
}
</style>