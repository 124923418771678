<template>
  <div class="Main" style="overflow-y:scroll">
    <div class="tableOne">
      <div class="search_and_profil add_search_and_profile">

        <div class="profile_box" v-if="!!isAdmin.first_name || !!isAdmin.last_name">
          <div class="cercle">
            <b-icon icon="circle-fill" animation="throb" font-scale="2" style="width: 20px; height: 20px;"></b-icon>
          </div>
          <div class="title">
            <h4>{{ isAdmin.first_name + " " + isAdmin.last_name }}</h4>
          </div>
          <div class="chevron">
            <b-icon icon="chevron-down" style="width: 15px; height: 15px;"></b-icon>
          </div>
        </div>

      </div>

      <div class="title_boxx ">
        <div @click="() => {$router.push(`/app/admins`)}" style="cursor: pointer;" class="back_link">
          <div class="back_icon">
            <b-icon icon="arrow-left" style="width: 25px; height: 25px; color:#2F3641"></b-icon>
          </div>
          <h2>Добавить пользователя</h2>
        </div>
      </div>

      <div class="table_boxx">
        <div class="row Form_box_row">
          <div class="col-lg-8">
            <b-form class="b_form" @submit.prevent="addUser">
              <div class="inputs_one">
                <!-- User name -->
                <div class="boxx">
                  <label class="title" for="user_name">Имя</label>
                  <b-form-input
                      class="inputt"
                      id="user_name"
                      required
                      v-model="name"
                  />
                </div>

                <!-- Password -->
                <div class="boxx">
                  <label class="title" for="password">Пароль</label>
                  <b-input-group>
                    <b-form-input
                        id="password"
                        required
                        :type="showPassword?'text':'password'"
                        v-model="password"
                        style="padding-left: 20px!important;"
                    />
                    <b-input-group-append>
                      <b-button size="sm" variant="outline-info" class="custom-wrapper-btn"
                                @click="showPassword =!showPassword">
                        <b-icon font-scale="2" :icon="showPassword?'eye-slash-fill':'eye-fill'"
                                variant="secondary"/>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>

                <!-- Status -->
                <div class="boxx">
                  <label class="title" for="role">Роль</label>
                  <select
                      class="select_inputt"
                      id="role"
                      required
                      v-model="role"
                  >
                    <option v-for="option in options" :value="option.value">{{ option.text }}</option>
                  </select>
                </div>

                <!-- Save Btn -->
                <div class="btn_box">
                  <button class="btn_box" type="submit">Сохранить</button>
                </div>

              </div>
              <div class="inputs_one">
                <!-- Last Name -->
                <div class="boxx">
                  <label class="title" for="user_name">Фамилия</label>
                  <b-form-input
                      class="inputt"
                      id="user_name"
                      required
                      v-model="last_name"
                  />
                </div>
                <div class="boxx">
                  <label class="title" for="email">Email</label>
                  <b-form-input
                      class="inputt"
                      id="email"
                      required
                      v-model="email"
                  />
                </div>
                <div class="boxx" v-if="role ==='customer'">
                  <label class="title" for="password">Событие</label>
                  <b-form-select
                      v-model="selectedEventId"
                      class="select_inputt"
                      size="lg"
                      style="height: 50px"
                      required
                  >
                    <b-form-select-option v-for="event in events" :value="event.id">{{
                        event.title.en
                      }}
                    </b-form-select-option>
                  </b-form-select>

                </div>
              </div>
            </b-form>
          </div>
          <b-overlay :show="busy" no-wrap></b-overlay>
        </div>
      </div>
    </div>
    <notes ref="message"></notes>
  </div>
</template>

<script>
// import {mapMutations, mapGetters, mapState} from 'vuex';
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import {instance} from "@/utils/composables";

export default {
  components: {
    // ToastificationContent,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      // Form information
      name: "",
      password: null,
      events: [],
      position: "",
      organisation: "",
      email: "",
      last_name: "",
      country: "",
      role: "moderator",
      selectedEventId: null,
      options: [
        {
          value: "admin", text: 'Админ'
        },
        {
          value: "customer", text: 'Клиент'
        },
        {
          value: "staff", text: 'Персонал'
        }
      ],
      file: null,
      busy: false,
      src: null,
      event: "",
      copy_link: "https://event.net",
      showPassword: false
    };
  },
  computed: {
    isAdmin() {
      return JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    getEvents() {
      instance.get("/event/?page=1&page_size=100", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(({data}) => {
        this.events = data.results;
      }).catch(({response}) => {
        this.$toast.error(response.data.message)
      });
    },

    // Click buttons
    browse() {
      this.$refs.file.click();
    },
    input(e) {
      this.file = e.target.files[0]
      const img = new FileReader()
      img.readAsDataURL(e.target.files[0])
      img.onload = (e) => {
        this.src = e.target.result
      }
    },
    async addUser() {
      try {
        this.busy = true;
        let formData = new FormData();

        formData.append('first_name', this.name);
        formData.append('last_name', this.last_name);
        formData.append('email', this.email);
        formData.append('role', this.role);
        formData.append('password', this.password);
        formData.append('event', this.role === 'customer' ? this.selectedEventId : '');

        await this.$store.dispatch("addAdmin", formData)
        this.$toast.success("Пользователь успешно создан")
        await this.$router.go(-1)
      } catch (err) {
        this.$toast.error(err)
        alert(err);
      } finally {
        this.busy = false
      }
    },
  },
  mounted() {
    this.getEvents()
  }
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #F8F7FF;
}

#role {
  color: #2F3641;
}

.inputt {
  color: #2F3641 !important;
}

.select_inputt {
  width: 100%;
  line-height: 20px !important;
  font-size: 16px !important;
  color: #2F3641;
  padding: 13px !important;
  border-radius: 15px;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.custom-wrapper-btn {
  box-shadow: none;
  border: 1px solid #ced4da !important;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  cursor: pointer;
}
</style>