<template>
  <div class="main">
    <div class="page-header">
      <h2 class="title">Hotel</h2>
      <div class="ations">

      </div>
    </div>
    <div class="page-info">
      <div class="booking-wrap">
        <p id='tl-anchor'>For registered participants of the event, we offer to book a room in one of the partner hotels
          for
          the duration of the forum at a special offer.</p>
        <div class="tl-container -type-booking">
          <ul class="bookmarks clearfix">
            <li id="hotel-1" class="active" data-id="15090">
              <p class="hotel-class">Daniel Hill Hotel</p>
            </li>
            <li id="hotel-2" data-id="27928">
              <p class="hotel-class">HUSMA HOTEL AND SPA</p>
            </li>
            <li id="hotel-3" data-id="15130">
              <p class="hotel-class">Ichan Qal`a Premium Class Hotel</p>
            </li>
            <li id="hotel-4" data-id="19914">
              <p class="hotel-class">Inspira-S</p>
            </li>
            <li id="hotel-5" data-id="500615">
              <p class="hotel-class">InterContinental Tashkent</p>
            </li>
            <li id="hotel-6" class="active" data-id="500065">
              <p class="hotel-class">Krokus Plaza</p>
            </li>
            <li id="hotel-7" data-id="21596">
              <p class="hotel-class">Manifa hotel</p>
            </li>
            <li id="hotel-8" data-id="22964">
              <p class="hotel-class">Manor Hotel</p>
            </li>
            <li id="hotel-9" data-id="27241">
              <p class="hotel-class">Milan Hotel Tashkent</p>
            </li>
            <li id="hotel-10" data-id="19353">
              <p class="hotel-class">NAVRUZ Hotel Tashkent</p>
            </li>
            <li id="hotel-11" class="active" data-id="20948">
              <p class="hotel-class">Oscar Boutique Hotel</p>
            </li>
            <li id="hotel-12" data-id="28624">
              <p class="hotel-class">Porto Bello Hotel</p>
            </li>
            <li id="hotel-13" data-id="500111">
              <p class="hotel-class">Regal Stay Hotel</p>
            </li>
            <li id="hotel-14" data-id="12501">
              <p class="hotel-class">The Royal Mezbon Hotel & Spa</p>
            </li>
            <li id="hotel-15" data-id="28498">
              <p class="hotel-class">Pan Asiat</p>
            </li>
            <li id="hotel-16" class="active" data-id="500648">
              <p class="hotel-class">Mirluxe Plaza Hotel</p>
            </li>
            <li id="hotel-17" data-id="20902">
              <p class="hotel-class">Rakat Plaza Hotel</p>
            </li>
            <li id="hotel-18" data-id="25466">
              <p class="hotel-class">Grand Mir Hotel</p>
            </li>
          </ul>
          <div class="blocks">
            <div class="blocks__booking-form">
              <select id="tl-hotel-select"></select>
              <div id="tl-booking-form">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingForm",
  mounted() {
    let select = document.getElementById("tl-hotel-select");
    select.addEventListener('change', function () {
      const hotel_id = "hotel_id";
      const regex = new RegExp(/hotel_id=\d+/g);
      const getHref = window.location.href;
      const getParams = window.location.search;
      const params_str = ((getParams === "") ? "?" : "") + hotel_id + "=" + this.value;
      let path = "";
      if (getParams.indexOf(hotel_id) !== -1) {
        path = getHref.replace(regex, params_str);
      } else {
        path = getHref + params_str;
      }
      window.history.pushState(false, false, path);
    });

    let elem;
    let i;

    function getParameterByName(name, url) {
      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, "\\$&");
      const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      console.log(results)
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    const bookmarksElements = document.querySelectorAll('.bookmarks li');
    if (bookmarksElements != null) {
      for (i = bookmarksElements.length - 1; i >= 0; i--) {
        elem = bookmarksElements[i];
        (function () {
          const prov = getParameterByName('hotel_id');
          console.log(prov)
          const el = elem.getAttribute('id');
          if (document.getElementById(el).getAttribute('data-id') === prov) {
            document.getElementById(el).classList.add('active');
          } else {
            document.getElementById(el).classList.remove('active');
          }

          if (!prov) {
            document.getElementById("hotel-1").classList.add('active');
          }
        })(elem)
      }
    }

    const selector = document.getElementById('tl-hotel-select'),
        listElement = document.querySelectorAll('.bookmarks li[id ^="hotel-"]');
    if (listElement != null) {
      for (i = listElement.length - 1; i >= 0; i--) {
        elem = listElement[i];
        (function (elem) {
          elem.addEventListener("click", function () {
            const listActiveElement = document.querySelector('.bookmarks li.active'),
                data_id = document.getElementById(elem.getAttribute('id')).getAttribute('data-id');

            listActiveElement.classList.remove('active');
            document.getElementById(elem.getAttribute('id')).classList.add('active');
            selector.value = data_id;
            fireEvent(selector[0], 'change');
          });
        })(elem)
      }
    }

    function getUrlParams() {
      const urlParams = {};
      const params = location.search.substr(1).split('&');
      for (let key in params) {
        const keyValuePair = params[key].split('=');
        urlParams[keyValuePair[0]] = decodeURIComponent(keyValuePair[1]);
      }
      console.log(urlParams)
      return urlParams;
    }

    function fireEvent(element, event) {
      if (document.createEventObject) {
        const ieEvt = document.createEventObject();
        return element.fireEvent('on' + event, ieEvt);
      } else {
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent(event, true, true);
        return !element.dispatchEvent(evt);
      }
    }

    (function (w) {

      function onConnectHandler(app) {
        app.pushArtifact('IiftForumKey', '132b369b823e783596b314594a845395');
      }

      const q = [
        ['setContext', 'TL-INT-iift_2023-03-07', 'en'],
        ['embed', 'booking-form', {
          container: 'tl-booking-form',
          onConnect: onConnectHandler
        }]
      ];
      const h = ["uz-ibe.tlintegration-as.com", "ibe.tlintegration-as.com", "ibe.tlintegrationfb-as.com"];
      const t = w.travelline = (w.travelline || {}),
          ti = t.integration = (t.integration || {});
      ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
      if (!ti.__loader) {
        ti.__loader = true;
        const d = w.document, c = d.getElementsByTagName("head")[0] || d.getElementsByTagName("body")[0];

        function e(s, f) {
          return function () {
            w.TL || (c.removeChild(s), f())
          }
        }

        (function l(h) {
          if (0 === h.length) return;
          const s = d.createElement("script");
          s.type = "text/javascript";
          s.async = !0;
          s.src = "https://" + h[0] + "/integration/loader.js";
          s.onerror = s.onload = e(s, function () {
            l(h.slice(1, h.length))
          });
          c.appendChild(s)
        })(h);
      }
    })(window);
  }
}
</script>

<style scoped lang="scss">
.main {
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.booking-wrap, .booking-wrap * {
  box-sizing: border-box;
}

.booking-wrap {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

#tl-anchor {
  margin-bottom: 40px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}

.bookmarks {
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-flow: row wrap;
}

.bookmarks p {
  margin: 0;
}

#tl-hotel-select {
  display: none;
}

#hotel-1,
#hotel-2,
#hotel-3,
#hotel-4,
#hotel-5,
#hotel-6,
#hotel-7,
#hotel-8,
#hotel-9,
#hotel-10,
#hotel-11,
#hotel-12,
#hotel-13,
#hotel-14,
#hotel-15,
#hotel-16,
#hotel-17,
#hotel-18 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  list-style: none;
  padding: 24px 32px;
  background: #124D97;
  border-radius: 100px;
  cursor: pointer;
  margin: 0 20px 20px 0;
  flex-grow: 1;
}

.hotel-class {
  text-transform: uppercase;
  color: #ffffff;
  border-bottom: 3px solid transparent;
}

.active .hotel-class {
  color: #FFC942;
  border-bottom: 3px solid #FFC942;
}
</style>