<template>
  <div class="user-header">
    <div class="logo-wrapper">
      <span style="cursor: pointer" @click="$router.push('/app/user-info')">
        <img src="@/assets/iift-logo.svg" alt="LOGO"/>
      </span>
    </div>
    <user-menu :is-title-white="true" class="user-menu"/>
    <div class="right-section d-flex gap-2">
      <div class="user-info">
        <b-dropdown no-caret class="m-2 custom-avatar-btn" variant="outline-light" offset="25">
          <template #button-content>
            {{ isAdmin.first_name + " " + isAdmin.last_name }}
            <b-avatar variant="danger" :src="isAdmin.image"></b-avatar>
          </template>
          <b-dropdown-item-button class="dr-button" @click="changePassword">
            <b-icon
                icon="lock-fill"
                variant="dark"
            ></b-icon>
            <span>Change password</span>
          </b-dropdown-item-button>
          <b-dropdown-item-button class="dr-button">
            <div class="logout" @click="logOut">
              <i class='bx bx-log-out'></i>
              <span>Exit</span>
            </div>
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
    </div>
    <b-modal
        id="modal-prevent-closing"
        ref="modal"
        centered
        v-model="modalShow"
        @show="resetModal"
        @hidden="resetModal"
        hide-footer
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label="Введите старый пароль"
            label-for="old-password"
        >
          <b-form-input
              id="old-password"
              class="name-input"
              v-model="formPassword.old_password"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group
            label="Введите новый пароль"
            label-for="new-password"
        >
          <b-form-input
              id="new-password"
              class="name-input"
              v-model="formPassword.new_password"
              required
          ></b-form-input>
        </b-form-group>
        <div style="display: flex">
          <b-button class="custom-btn" @click="modalShow = false">Cancel</b-button>
          <b-button class="custom-btn" variant="primary" type="submit">Saved</b-button>
        </div>
      </b-form>
      <b-overlay :show="busyPassword" no-wrap></b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {Navigations} from "@/router/navigations";
import UserMenu from "@/components/header/UserMenu.vue";
import {instance} from "@/utils/composables";
import router from "@/router";

export default {
  name: "UserHeader",
  components: {UserMenu},
  data() {
    return {
      busyPassword: false,
      modalShow: false,
      formPassword: {
        old_password: null,
        new_password: null,
      },
    }
  },
  computed: {
    isAdmin() {
      return JSON.parse(localStorage.getItem('user'))
    },
    navigations() {
      let tempNav = [];
      if ((this.$route.name === 'sobity' || this.$route.name === 'admins') && this.isAdmin.role !== 'user') {
        tempNav.push(Navigations[0]);
        tempNav.push(Navigations[1]);
      } else {
        Navigations.forEach(item => {
          if (item.main && item.name !== 'admins') {
            tempNav.push(item)
          }
        });
      }
      return tempNav
    }
  },
  methods: {
    router() {
      return router
    },
    resetModal() {
      this.formPassword = {
        old_password: null,
        new_password: null,
      }
    },
    changePassword(userId) {
      this.selectedUser = userId
      this.$refs.modal.show()
    },
    handleSubmit() {
      this.busyPassword = true;
      let formData = new FormData();
      formData.append('old_password', this.formPassword.old_password);
      formData.append('new_password', this.formPassword.new_password);
      if (this.isAdmin.id) {
        instance.post(`user/${this.isAdmin.id}/password-change/`, formData)
            .then(() => {
              this.$refs.modal.hide();
              this.$toast.success("Пароль успешно изменен");
            })
            .catch(() => {
              this.$toast.error('Что-то пошло не так')
            }).finally(() => {
          this.busyPassword = false
        })
      }
    },
    logOut() {
      this.$bvModal.msgBoxConfirm('Вы действительно хотите выйти?', {
        title: 'Предупреждение',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да',
        okClass: 'btn-icon',
        cancelTitle: 'Отмена',
        cancelVariant: 'outline-dark',
        cancelClass: 'btn-icon',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if (value) {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              localStorage.removeItem('user');
              localStorage.removeItem('event');
              this.$router.push('/login');
            }
          })
    },
  }
}
</script>

<style scoped lang="scss">
.user-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ECEFF8;
  padding: 0 20px;
  max-width: 100vw;
  background-color: rgb(0, 56, 116);

  @media (min-width: 900px) {
    box-shadow: 0 1px 8px rgb(0 0 0 / 10%);
  }
}

.logo-wrapper {
  img {
    height: 55px;
  }
}

.left-section {
  align-items: center;
  height: 100%;
}


.right-section {
  height: 100%;
  align-items: center;

  .logout {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    i {
      font-size: 20px;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 900px) {
  .user-menu {
    display: none;
  }
}

.dr-button {
  font-size: 14px;

  :deep(button) {
    padding: 15px 5px;
    //color: #ffffff;
  }

  &:first-child {
    border-bottom: 1px solid #ECEFF8;
  }

  span {
    margin-left: 5px;
  }
}

.custom-avatar-btn:deep(.dropdown-toggle) {
  display: flex;
  align-items: center;
  box-shadow: none;
  gap: 10px;
  text-transform: capitalize;

  &:hover, &:focus, &:active {
    box-shadow: none !important;
  }
}

:deep(.btn-outline-light) {
  &.dropdown-toggle, &:active {
    box-shadow: none;
  }
}

.modal-dialog .modal-content {
  padding: 15px !important;
}

.form-group {
  margin-top: 20px;
}

.name-input {
  width: 100%;
  margin-top: 8px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.custom-btn {
  margin-top: 20px;
  width: 50%;
  padding: 8px 0;
  border-radius: 15px;
}
</style>