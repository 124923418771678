<template>
  <div class="Main" style="overflow-y: scroll">
    <div class="tableOne">
      <div class="title_boxx">
        <div>
          <h2>Участники</h2>
        </div>
        <div class="double_btn">
          <div @click="() => $refs.xls.click()" class="print">
            <input
                type="file"
                style="display: none"
                ref="xls"
            />
            <button>
              <b-icon class="icon" icon="download" style="width: 20px; height: 20px; margin-right: 10px"/>
              Импорт XLSX
            </button>
          </div>
        </div>
      </div>

      <div class="table_box">
        <div class="table_title with_category">
          <div>
            <h3>Последние Зарегистрированные</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {instance} from "@/utils/composables";

export default {
  name: "ImportUsers",
  data() {
    return {
      loading: false,
      file: null
    }
  },
  methods: {
    upload(e) {
      this.loading = true;
      this.file = e.target.files[0];
      const data = new FormData();
      data.append("event", this.$route.params.id);
      data.append("file", this.file);

      instance.post("/participant/import/", data, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(() => {
        //   TODO load imported users here
      }).catch((err) => {
        this.$toast.error(err.response.data.message)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store.commit("setCurrentId", this.$route.params.id);
    }
  },
}
</script>

<style scoped>

</style>