<template>
  <div class="Main" style="overflow-y:scroll">
    <div class="tableOne">
      <div class="search_and_profil add_search_and_profile">

        <div class="profile_box">
          <div class="cercle">
            <b-icon icon="circle-fill" animation="throb" font-scale="2" style="width: 20px; height: 20px;"></b-icon>
          </div>
          <div class="title">
            <h4>Admin</h4>
          </div>
          <div class="chevron">
            <b-icon icon="chevron-down" style="width: 15px; height: 15px;"></b-icon>
          </div>
        </div>

      </div>

      <div class="title_boxx ">
        <div @click="()=> {$router.go(-1)}" class="back_link" style="cursor:pointer;">
          <div class="back_icon">
            <b-icon icon="arrow-left" style="width: 25px; height: 25px; color:#2F3641"></b-icon>
          </div>
          <h2>Создать события</h2>
        </div>
      </div>

      <div class="table_box shablon_in">
        <div class="Form_box">
          <!-- Form box -->
          <div class="form_box form_settengs">
            <b-form @submit.prevent="AddSobity" class="w-100">
              <div class="form_in formIn">
                <!-- Event Title -->
                <div class="boxx">
                  <label class="title" for="user_name">Название события</label>
                  <b-form-input
                      class="inputt"
                      id="user_name"
                      required
                      v-model="event_title"
                  />
                </div>
                <div class="boxx">
                  <label class="title" for="user_name">Название Организации</label>
                  <b-form-input
                      class="inputt"
                      id="user_name"
                      required
                      v-model="org"
                  />
                </div>
                <!-- Data-->
                <div class="boxx">
                  <label class="title" for="Position">Дата</label>
                  <input
                      type="date"
                      class="datePick"
                      id="Position"
                      required
                      v-model="data"
                  />
                </div>
                <div class="boxx">
                  <label class="title" for="Position">Время начало</label>
                  <input
                      type="time"
                      class="datePick"
                      id="Position"
                      required
                      v-model="timeStart"
                  />
                </div>
                <!-- Organisation -->
                <div class="boxx">
                  <label class="title" for="end_data">Дата окончания</label>
                  <input
                      id="end_data"
                      class="datePick"
                      type="date"
                      required
                      v-model="end_data"
                  />
                </div>
                <div class="boxx">
                  <label class="title" for="Position">Время окончания</label>
                  <input
                      type="time"
                      class="datePick"
                      id="Position"
                      required
                      v-model="timeEnd"
                  />
                </div>

                <!-- Link Fild-->
                <div class="boxx">
                  <label class="title" for="link">Ссылка</label>
                  <b-form-input
                      class="inputt"
                      id="link"
                      required
                      v-model="link"
                  />
                </div>
                <div class="boxx">
                  <label class="title" for="link">Адрес</label>
                  <b-form-input
                      class="inputt"
                      id="link"
                      required
                      v-model="address"
                  />
                </div>
                <!-- Btn box -->
                <div class="btn_box">
                  <button class="btn_box" type="submit">Сохранить</button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
        <b-overlay :show="busy" no-wrap></b-overlay>
      </div>
    </div>
    <!-- Alert component -->
    <notes ref="message"></notes>
  </div>
</template>

<script>
import setAuthHeader from '@/utils/setAuthHeader'
// import {mapMutations, mapGetters, mapState} from 'vuex';
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import {instance} from "@/utils/composables";
import router from '@/router';

export default {
  components: {
    // ToastificationContent,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      // Form information
      event_title: "",
      busy: false,
      org: null,
      address: "",
      data: null,
      timeStart: "",
      timeEnd: "",
      end_data: null,
      link: "https://domain.com",
      copy_link: "https://event.net",
    }
  },
  methods: {
    async AddSobity() {
      try {
        this.busy = false;
        let formData = new FormData();
        let title = {
          uz: this.event_title,
          ru: this.event_title,
          en: this.event_title,
        };
        formData.append('start_date', `${this.data} ${this.timeStart}`);
        formData.append('end_date', `${this.end_data} ${this.timeEnd}`);
        formData.append('organization', this.org);
        formData.append('address', this.address);
        formData.append('link', this.link);
        formData.append('title', JSON.stringify(title));

        const response = await instance.post("/event/", formData, {
          headers: {
            "Authorization": `Bearer ${localStorage.accessToken}`
          }
        })
        this.$toast.success("Событие успешни создано")
        await this.$router.push("/app/sobity")
      } catch (err) {
        this.$toast.error(err)
      } finally {
        this.busy = false;
      }
    },
  }
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #F8F7FF;
}

.datePick {
  font-size: 16px !important;
  color: #2F3641;
  border: 1px solid #ced4da;
  padding: 13px 10px !important;
  border-radius: 15px;
}


</style>