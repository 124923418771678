import Sobity from "@/views/Sobity/Sobity.vue";
import AddSobity from "@/views/Sobity/AddSobity.vue";
import EditSobity from "@/views/Sobity/EditSobity.vue";
import Users from "@/views/Uchasnik/Users.vue";
import AddUser from "@/views/Uchasnik/AddUser.vue";
import Shablon from "@/views/Shablon/Shablon.vue";
import ImportUsers from "@/views/Uchasnik/ImportUsers.vue";
import RegisterForm from "@/views/Shablon/RegisterForm.vue";
import Settings from "@/views/Nastroyke/Settings.vue";
import Information from "@/views/Information/Information.vue";
import ModalForm from "@/components/BootstrapComp/ModalForm.vue";
import Admin from "@/views/Admin.vue";
import AddSuperuser from "@/views/AddSuperuser.vue";
import EditAdmin from "@/views/EditAdmin.vue";
import QR from "@/views/QR.vue";
import UserInfo from "@/views/personal-cabinet/UserInfo.vue";
import BookedTransports from "@/views/personal-cabinet/transports/BookedTransports.vue";
import BookingForm from "@/components/hotel/BookingForm.vue";
import CredentialUsers from "@/views/Uchasnik/CredentialUsers.vue";
import UserSession from "@/views/personal-cabinet/UserSession.vue";
import FlightDetails from "@/views/personal-cabinet/FlightDetails.vue";
import FlightDetailsForm from "@/views/personal-cabinet/FlightDetailsForm.vue";
import GuidedTours from "@/views/personal-cabinet/GuidedTours.vue";

export const Navigations = [
    {
        main: true,
        path: 'sobity',
        name: 'sobity',
        title: "События",
        icon: 'fas fa-calendar',
        // icon: 'bx bx-log-out',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: Sobity
    },
    {
        main: true,
        path: 'admins',
        name: 'admins',
        title: 'Пользователи',
        icon: 'fas fa-user',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: Admin,
    },
    {
        main: true,
        path: 'credential/users',
        name: 'credential-users',
        title: 'Участники с полномочиями',
        icon: 'fas fa-user',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: CredentialUsers,
    },
    {
        main: false,
        path: 'addSobity',
        name: 'sobityAdd',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: AddSobity
    },
    {
        main: false,
        path: 'editSobity/:id',
        name: 'editSobity',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: EditSobity,

    },
    {
        main: true,
        path: 'information',
        name: 'information',
        title: 'Информация',
        icon: 'fas fa-info-circle',
        meta: {layout: 'main', authorize: ["ADMIN", "CUSTOMER", "STAFF"]},
        component: Information
    },
    {
        main: true,
        path: 'users',
        name: 'users',
        title: "Участники",
        icon: "fas fa-user-circle",
        meta: {layout: 'main', authorize: ["ADMIN", "CUSTOMER", "STAFF"]},
        component: Users,
    },
    {
        main: false,
        path: 'addUser',
        name: 'addUser',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: AddUser
    },
    {
        main: false,
        path: 'editUser/:id',
        name: 'editUser',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: AddUser
    },
    {
        main: true,
        path: 'shablon',
        name: 'shablon',
        title: 'Шаблон',
        icon: 'fas fa-align-left',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: Shablon
    },
    {
        main: false,
        path: 'users/import',
        name: 'usersImport',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: ImportUsers
    },
    {
        main: true,
        path: 'register-form',
        name: 'register-form',
        title: 'Форма регистрации',
        icon: 'fas fa-clipboard-list',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: RegisterForm
    },
    {
        main: true,
        path: 'settings',
        name: 'settings',
        title: "Настройки",
        icon: 'fas fa-cog',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: Settings
    },
    {
        main: false,
        path: 'modalform',
        name: 'modalform',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: ModalForm,

    },
    {
        main: false,
        path: 'addSuperuser',
        name: 'adminAdd',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: AddSuperuser
    },
    {
        main: false,
        path: 'editAdmin/:id',
        name: 'editAdmin',
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: EditAdmin
    },
    {
        main: false,
        path: "user-check/:id",
        name: "qr",
        meta: {layout: 'main', authorize: ["ADMIN", "STAFF"]},
        component: QR
    },
//     personal cabinet
    {
        main: true,
        path: "user-info",
        name: "UserInfo",
        title: 'Information',
        meta: {layout: 'main', authorize: ["USER"]},
        component: UserInfo
    },
    {
        main: true,
        path: "booked/transports",
        name: "BookedTransports",
        title: 'Transports',
        icon: 'bx bx-car',
        meta: {layout: 'main', authorize: ["USER"]},
        component: BookedTransports
    },
    {
        main: true,
        path: "hotel",
        name: "HotelBooking",
        title: 'Hotel',
        icon: 'bx bx-hotel',
        meta: {layout: 'main', authorize: ["USER"]},
        component: BookingForm
    },
    {
        main: true,
        path: "users/sessions",
        name: "UserSession",
        title: 'Sessions',
        icon: 'bx bxs-calendar-event',
        meta: {layout: 'main', authorize: ["USER"]},
        component: UserSession
    },
    {
        main: false,
        path: "flight/details",
        name: "FlightDetails",
        title: 'Flight details',
        icon: 'bx bxs-plane-take-off',
        meta: {layout: 'main', authorize: ["USER"]},
        component: FlightDetails
    },
    {
        main: true,
        path: "flight/detail/add",
        name: "FlightDetailsFormAdd",
        title: 'Flight details',
        icon: 'bx bxs-plane-take-off',
        meta: {layout: 'main', authorize: ["USER"]},
        component: FlightDetailsForm
    },
    {
        main: false,
        path: "flight/detail/edit",
        name: "FlightDetailsFormEdit",
        meta: {layout: 'main', authorize: ["USER"]},
        component: FlightDetailsForm
    },
    {
        main: true,
        path: "guid/tours",
        name: "GuidedTours",
        title: 'Guided tours',
        icon: 'bx bxs-calendar-event',
        meta: {layout: 'main', authorize: ["USER"]},
        component: GuidedTours
    }
]

