<template>
  <div class="qrChecker">
    <div v-if="user">
      <div class="qrSuccess">Success</div>
      <div>
        <img v-if="user && user.image" :src="user.image" alt="userImage" />
      </div>
      <div class="userNameQr" v-if="user && user.user">
        {{ user.user }}
      </div>
      <div class="userOrgQr" v-if="user && user.organization">
        {{ user.organization }}
      </div>
      <div class="userOrgQr" v-if="user && user.event">
        {{ user.event }}
      </div>
    </div>
    <div v-if="!user">
      <div class="qrSuccessEr">Error</div>
      <div>
        <img src="@/assets/failed.jpg" alt="userImage" />
      </div>
      <div class="userNameQr" >
        Участник не найден
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        user: null,
      };
    },
    async created() {
      if (this.$route.params.id) {
        await this.$store.dispatch("checkQrCode", this.$route.params.id);
        this.user = this.$store.state.user;
      } else {
        alert("Error");
      }
    },
  };
</script>

<style lang="scss">
  .qrChecker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    .qrSuccess {
      font-size: 2em;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 20px;
      text-align: center;
      color: green;
    }
    .qrSuccessEr {
font-size: 2em;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 20px;
      text-align: center;
      color: red;
    }
    .userNameQr {
      margin: 20px 0 10px 0;
      font-size: 1.5em;
    }
    .userOrgQr {
      font-size: 1.1em;
      margin-bottom: 10px;
    }
    img {
      border-radius: 50%;
      height: 100px;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }
</style>
