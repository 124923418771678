<template>
  <div class="Main">
    <div class="tableOne">
      <div class="text">
        Please fill out the form so we can meet you and provide transportation
      </div>

      <div class="title_boxx ">
        <span class="title">{{ formTitle }}</span>
      </div>

      <div class="table_boxx">
        <div class="row Form_box_row">
          <div class="col-lg-10 col-md-12" style="margin: 0 auto">
            <b-form class="b_form" @submit.prevent="onSubmit">
              <div class="inputs_one">
                <!-- User name -->
                <div class="boxx">
                  <label class="title" for="flight_number">Flight number
                    <span>(Arrival)</span>
                  </label>
                  <b-form-input
                      class="inputt"
                      id="flight_number"
                      type="text"
                      required
                      v-model="form.flight_number"
                  />
                </div>
                <div class="row w-100 m-auto">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <fieldset style="width: 100%">
                      <legend>
                        Arrival info
                        <img style="height: 30px; margin-left: 4px; transform: rotate(45deg)"
                             src="@/assets/airplane.png"/>
                      </legend>

                      <div class="boxx">
                        <label class="title" for="arrival_date">Date</label>
                        <b-form-input
                            class="inputt"
                            id="arrival_date"
                            type="date"
                            required
                            v-model="form.arrival_date"
                        />
                      </div>

                      <div class="boxx">
                        <label class="title" for="arrival_time">Time</label>
                        <b-form-input
                            class="inputt"
                            id="arrival_time"
                            type="time"
                            required
                            v-model="form.arrival_time"
                        />
                      </div>

                    </fieldset>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <fieldset style="width: 100%">
                      <legend>
                        Departure info
                        <img style="height: 30px; margin-left: 4px"
                             src="@/assets/airplane.png"/>
                      </legend>

                      <div class="boxx">
                        <label class="title" for="arrival_date">Date</label>
                        <b-form-input
                            class="inputt"
                            id="arrival_date"
                            type="date"
                            required
                            v-model="form.departure_date"
                        />
                      </div>

                      <div class="boxx">
                        <label class="title" for="arrival_time">Time</label>
                        <b-form-input
                            class="inputt"
                            id="arrival_time"
                            type="time"
                            required
                            v-model="form.departure_time"
                        />
                      </div>

                    </fieldset>
                  </div>
                </div>
                <!-- Save Btn -->
                <div class="btn_box">
                  <button class="btn_box" type="submit">Save</button>
                </div>

              </div>
              <b-overlay :show="loading" no-wrap></b-overlay>
            </b-form>
          </div>

        </div>
      </div>
    </div>
    <notes ref="message"></notes>
  </div>
</template>

<script>
import {instance} from "@/utils/composables";
import moment from "moment";

export default {
  name: "FlightDetailsForm",
  data() {
    return {
      loading: false,
      form: {
        flight_number: null,
        arrival_date: null,
        arrival_time: null,
        departure_date: null,
        departure_time: null
      }
    }
  },
  computed: {
    formTitle() {
      return this.$route.name === 'FlightDetailsFormEdit' ? "Edit flight details" : "Add flight details"
    }
  },
  methods: {
    getFlightDetail() {
      this.loading = true;
      instance.get("/user/flight-detail/", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(({data}) => {
        if (Object.keys(data).length) {
          this.form.flight_number = data.flight_number;
          this.form.departure_date = data.flight_departure_datetime?.split('T')[0];
          this.form.departure_time = moment(data.flight_departure_datetime).format('HH:mm');
          this.form.arrival_date = data.flight_arrival_datetime?.split('T')[0];
          this.form.arrival_time = moment(data.flight_arrival_datetime).format('HH:mm');
        }
      }).catch(({error}) => {
        this.$toast.error(error.response.data.message)
      }).finally(() => {
        this.loading = false;
      });
    },
    onSubmit() {
      this.loading = true;
      let sendForm = new FormData();

      sendForm.append('flight_number', this.form.flight_number);
      sendForm.append('flight_departure_datetime', this.form.departure_date + "T" + this.form.departure_time);
      sendForm.append('flight_arrival_datetime', this.form.arrival_date + "T" + this.form.arrival_time);

      instance.post("/user/flight-detail/", sendForm, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(() => {
        this.$toast.success("Flight detaul successfully added");
      }).catch(({error}) => {
        this.$toast.error(error.response.data.message)
      }).finally(() => {
        this.loading = false;
      });
    },

  },
  mounted() {
    this.getFlightDetail()
  }
}
</script>

<style scoped lang="scss">
.Main {
  height: 100%;
  width: 100%;
  background: #F8F7FF;
}

.text, .title {
  padding: 5px;
  width: 100%;
  font-weight: 500;
  color: #2F3641;
}

.text {
  background-color: #ffffff;
}

#role {
  color: #2F3641;
}

.inputt {
  color: #2F3641 !important;
}

.select_inputt {
  width: 100%;
  line-height: 20px !important;
  font-size: 16px !important;
  color: #2F3641;
  padding: 13px !important;
  border-radius: 15px;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.custom-wrapper-btn {
  box-shadow: none;
  border: 1px solid #ced4da !important;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  cursor: pointer;
}

.inputs_one {
  width: 100% !important;
}

fieldset {
  margin-top: 8px;
  padding: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 10px;

  legend {
    border-bottom: 1px solid #e4e4e4;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>