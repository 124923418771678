import axios from "axios";
import store from "@/store";
import router from "@/router";

const API_URL = process.env.VUE_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,
  transformRequest: (data, headers) => {
    const accessToken = localStorage.getItem("accessToken");
    // console.log('localStorage.accessToken:', localStorage.accessToken);
    if (accessToken) {
      headers["Authorization"] = `Bearer ${localStorage.accessToken}`;
    }
    // console.log("data:", data);
    // console.log("headers:", headers);
    return data;
  }
});

instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error.response);

      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      if (!store.state.refreshToken) {
        store.commit("logOut");
        router.push("/").then(r => console.log(r));
        return Promise.reject(error);
      }

      return axios.post(`${API_URL}/user/refresh/`, {
        refresh: store.state.refreshToken
      }).then(({data}) => {
        let payloadData = {
          accessToken: data.access,
          refreshToken: data.refresh,
        }
        store.commit("setTokens", payloadData);
        return instance(error.response.config);
      }).catch((error) => {
        store.commit("logOut");
        router.push('/login');
        return Promise.reject(error);
      })
    }
);

export {instance};