import Vue from "vue";
import Vuex from "vuex";
import RigstrPro from "./market/RigstrProduct";
import Table from "@/store/Table/Table.js";
import router from "@/router";

// Integrate
import {instance} from "@/utils/composables";
import axios from "axios";
import toast from "bootstrap/js/src/toast";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        menu: true,
        user: null,
        accessToken: null,
        refreshToken: null,
        currentID: null,
        events: [],
        eventRegistrationTypes: [],
        participants: [],
        shablon: null,
        forms: null,
        config: null,
        info: null,
        hosts: null,
        admins: null,
        exactAdmin: null,
        admined: null,
    },
    mutations: {
        setAdmined(state, payload) {
            state.admined = payload;
        },
        setExactAdmin(state, payload) {
            state.exactAdmin = payload;
        },
        setAdmins(state, payload) {
            state.admins = payload;
        },
        setHosts(state, payload) {
            state.hosts = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setEvents(state, events) {
            state.events = events;
        },
        setEventRegistrationTypes(state, eventRegistrationTypes) {
            state.eventRegistrationTypes = eventRegistrationTypes;
        },
        setToken(state, payload) {
            state.accessToken = payload.access_token;
            state.refreshToken = payload.refresh_token;
        },
        setParticipants(state, participants) {
            state.participants = participants;
        },
        setToggle(state, toggle) {
            state.menu = toggle;
        },
        setCurrentId(state, payload) {
            state.currentID = payload;
        },
        setShablon(state, payload) {
            state.shablon = payload;
        },
        setForms(state, payload) {
            state.forms = payload;
        },
        setConfig(state, payload) {
            state.config = payload;
        },
        setInfo(state, payload) {
            state.info = payload;
        },
        setTokens(state, payload) {
            localStorage.setItem("accessToken", payload.accessToken);
            localStorage.setItem("refreshToken", payload.refreshToken);
            state.accessToken = payload.accessToken;
            state.refreshToken = payload.refreshToken;
        },
        logOut() {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
        },
    },
    actions: {
        async getHosts({commit}) {
            try {
                const response = await instance("/email-host/", {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
                // console.log(response.data);
                commit("setHosts", response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async getEvents({commit}, payload) {
            // console.log(payload);
            try {
                const response = await instance.get(`/event/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
                commit("setEvents", response.data);
            } catch (error) {
                console.log(error);
                await router.push("/");
            }
        },
        async getEventRegistrationTypes({commit}, event_id) {
            try {
                const response = await instance.get(`/event/${event_id}/registration-types/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
                commit("setEventRegistrationTypes", response.data);
            } catch (error) {
                console.log(error);
                await router.push("/");
            }
        },
        async deleteItem({commit}, id) {
            await instance.delete(`/event/${id}/`);
        },
        async getAllParticipants({commit}, payload) {
            try {
                const response = await instance.get(
                  `/participant/?event=${payload.event}&registration_type=${payload.registration_type}&status=${payload.status}&country=${payload.country}&page=${payload.page}&page_size=${payload.page_size}`,
                  {
                      headers: {
                          Authorization: `Bearer ${localStorage.accessToken}`,
                      },
                  }
                );
                commit("setParticipants", response.data);
            } catch (error) {
                console.log(error);
                await router.push("/");
            }
        },
        async addUser({commit}, data) {
            const response = await instance.post("/participant/", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
            });
            // console.log(response);
        },
        async deleteUser({commit}, id) {
            try {
                const response = await instance.delete(`/participant/${id}/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
            } catch (error) {
                console.log(error);
                console.log('Что-то пошло не так')
                router.push("/app/users/").then(() => {
                });
            }
        },
        async getInformation({commit}, id) {
            try {
                const response = await instance.get(`/event/${id}/info/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
                commit("setInfo", response.data);
            } catch (error) {
                console.log(error);
            }
        },
        async getShablon({commit}, id) {
            try {
                const response = await instance.get(`/event/${id}/template/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
                commit("setShablon", response.data);
            } catch (error) {
                console.log(error);
            }
        },

        async getForms({commit}, payload) {
            // console.log(payload);
            try {
                const response = await instance.get(`/event/${payload}/form/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
                // console.log(response.data);
                commit("setForms", response.data);
            } catch (err) {
                console.log(err);
                await router.push("/");
            }
        },
        async updateForm({commit}, payload) {
            await instance.patch(`/event/form/${payload.id}/`, payload.main, {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`,
                },
            });
        },
        async uploadFile({commit}, payload) {
            try {
                const response = await instance.post("/participant/import/", payload, {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
            } catch (err) {
                console.log(err);
            }
        },
        async patchUser({commit}, payload) {
            await instance.patch(`/participant/${payload.id}/`, payload.data, {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`,
                },
            });
        },
        async getConfig({commit}, payload) {
            try {
                const response = await instance.get(`/event/${payload}/config/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
                commit("setConfig", response.data);
            } catch (err) {
                console.log(err);
                await router.push({name: "PageNotFound"});
            }
        },
        async saveConfig({commit}, payload) {
            await instance.put(`/event/config/${payload.id}/`, payload.formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`,
                },
            });
        },
        async checkQrCode({commit}, payload) {
            try {
                const response = await instance.get(
                  `/participant/check/?public_id=${payload}`,
                  {
                      headers: {
                          Authorization: `Bearer ${localStorage.accessToken}`,
                      },
                  }
                );
                commit("setUser", response.data);
            } catch (err) {
                console.log(err);
            }
        },
        async deleteMultiple({commit}, payload) {
            await instance.delete("/participant/multiple-delete/", {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        async export({commit}, payload) {
            const response = await instance.get(
              `/participant/export/?event=${payload.event}&status=${payload.status}&country=${payload.country}&registration_type=${payload.registration_type}`,
              {
                  responseType: "blob",
              }
            );
            const contentDisposition = response.headers['content-disposition'];

            let filename = "participants.xlsx";

            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                // console.log('matches:', matches);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
                }
                // console.log('Filename:', filename);
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        },
        async getAllUsers({commit}) {
            const response = await instance.get("/user/?role=admin&role=staff&role=customer", {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`,
                },
            });
            commit("setAdmins", response.data);
        },
        async addAdmin({commit}, payload) {
            await instance.post("/user/register/", payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`,
                },
            });
        },
        async deleteAdmin({commit}, payload) {
            const response = await instance.delete(`/user/${payload}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`,
                },
            });
            // console.log(response);
        },
        async getExactUser({commit}, payload) {
            const response = await instance.get(`/user/${payload}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`,
                },
            });
            commit("setExactAdmin", response.data);
        },
        async patchAdmin({commit}, payload) {
            await instance.patch(`/user/${payload.id}/`, payload.formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`,
                },
            });
        },
        async getDetailails({commit}) {
            if (localStorage.accessToken) {
                const response = await instance.get("/user/me/", {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`,
                    },
                });
                // if (response.data.role === "moderator") {
                //     localStorage.setItem("event", "2");
                // }
                commit("setAdmined", response.data);
                localStorage.setItem('event', response.data.event?.id);
                localStorage.setItem("user", JSON.stringify(response.data));
                await router.push({name: "sobity"});
            } else {
                commit("setAdmined", null);
            }
        },
    },
    modules: {
        // for example ===
        RigstrPro,
        Table,
    },
});

if (localStorage.accessToken && localStorage.refreshToken) {
    const payload = {
        accessToken: localStorage.accessToken,
        refreshToken: localStorage.refreshToken,
    };
    store.commit("setTokens", payload);
}

export default store;
