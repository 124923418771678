<template>
  <div class="d-flex allContent">
    <div class="border-right leftmenu">
      <div class="main_boxx">
        <div class="logo_chevron">
          <div class="company_title">
            <!-- <img src="@/assets/iift-logo.svg" alt="M"> -->
          </div>
        </div>
      </div>
      <div class="Menyu_box">
        <div class="main_link">
          <div
            class="menu-item w-100"
            v-for="(nav, index) in navigations"
            :key="index"
          >
            <router-link
              :to="'/app/' + nav.path"
              v-if="nav.meta.authorize.includes(isAdmin.role.toUpperCase())"
              class="main_btn main_btn_bac"
              :class="$route.name === nav.name && 'router-link-active'"
            >
              <div class="down_icon">
                <i
                  class="icon"
                  :class="nav.icon ? nav.icon : 'bx bx-info-circle'"
                ></i>
              </div>
              <div class="down_title">
                <p>{{ nav.title }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="contents">
        <b-button @click="logOut" variant="white" class="mb-1 text-black">
          <b-icon icon="door-open" aria-hidden="true"></b-icon>
        </b-button>
        <!-- <a class="log_out">
          <div class="img-hide">
            &lt;!&ndash; <i class="bx bx-log-out"></i> &ndash;&gt;
            <b-icon
              icon="door-open"
              aria-hidden="true"
              class="fs-2"
              @click="logOut"
            ></b-icon>
          </div>
        </a> -->
      </div>
    </div>
    <div class="main bg-white">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mdbIcon } from "mdbvue";
import { Navigations } from "@/router/navigations";

export default {
  name: "AdminLayout",
  components: {
    mdbIcon,
  },
  data() {
    return {};
  },
  computed: {
    isAdmin() {
      return JSON.parse(localStorage.getItem("user"));
    },
    navigations() {
      let tempNav = [];
      if (
        (this.$route.name === "sobity" ||
          this.$route.name === "sobityAdd" ||
          this.$route.name === "editSobity" ||
          this.$route.name === "adminAdd" ||
          this.$route.name === "editAdmin" ||
          this.$route.name === "credential-users" ||
          this.$route.name === "admins") &&
        this.isAdmin.role !== "user"
      ) {
        tempNav.push(Navigations[0]);
        tempNav.push(Navigations[1]);
        tempNav.push(Navigations[2]);
      } else {
        Navigations.forEach((item) => {
          if (
            item.main &&
            item.name !== "admins" &&
            item.name !== "credential-users"
          ) {
            tempNav.push(item);
          }
        });
      }
      return tempNav;
    },
  },
  methods: {
    logOut() {
      this.$bvModal
        .msgBoxConfirm("Вы действительно хотите выйти?", {
          title: "Предупреждение",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Да",
          okClass: "btn-icon",
          cancelTitle: "Отмена",
          cancelVariant: "outline-dark",
          cancelClass: "btn-icon",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("user");
            localStorage.removeItem("event");
            this.$router.push("/login");
          }
        });
    },
  },
};
</script>

<style lang="scss">
$blackk: #363738;
$dropdown-box-shadoww: 0 5px 10px 0 rgba($blackk, 0.4) !default;

.main {
  width: calc(100vw - 280px);
}

.router-link-active {
  background: #354b9c !important;

  p {
    color: #fff !important;
  }

  i {
    color: #fff !important;
  }
}

.Menyu_box {
  .main_btn_bac {
    text-decoration: none;
    border-radius: 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 15px;
    width: 100%;

    .icon_box_bac {
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        color: #354b9c;
        font-size: 30px;
      }
    }

    .title_box {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 18px;
        line-height: 30px;
        color: #354b9c;
        font-weight: 600;
        margin: 0;
        text-align: start;
      }
    }

    &:hover {
      background: #354b9c;

      p {
        color: #fff;
      }

      i {
        color: #fff !important;
      }
    }
  }
}

.leftmenu {
  width: 280px;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  background: #003874;
  font-size: 14px;
  padding: 30px 16px;
}

.logo_chevron {
  text-align: center;
  width: 100%;

  .company_title {
    margin: 40px 0;
    cursor: pointer;

    img {
      border: none;
      width: 100px;
    }
  }
}

.menu-item {
  margin: 15px 0;
}

.down_icon {
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    // color: #BCC3CF;
    color: #676d7c;
    font-size: 30px;
  }
}

.down_title {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
    line-height: 30px;
    color: #676d7c;
    font-weight: 500;
    margin: 0;
    text-align: start;
  }
}

.menyu_inbox {
  background: red;
}

.activeDefault {
  background: #354b9c !important;
  color: white !important;
  transition: all 0.3s ease-in-out;
}

.contents {
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
}

.contents .log_out {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
}

.img-hide {
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-hide i {
  font-size: 30px;
  color: #676d7c;
}

.header-top {
  height: 40px;
  width: 100%;
}
</style>