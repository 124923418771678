<template>
  <div class="menu">
    <template v-for="(nav,index) in navigations">
      <router-link :to="'/app/'+nav.path"
                   :key="index"
                   v-if="nav.meta.authorize.includes(isAdmin.role.toUpperCase())"
                   class="menu-item-link"
                   :class="$route.name===nav.name&&'active'">
        <div class="down_icon" :class="{'icon-white':isTitleWhite}">
          <i class='icon' :class="nav.icon? nav.icon:'bx bx-info-circle'"></i>
        </div>
        <div class="down_title">
          <p :class="{'custom-cls':isTitleWhite}">{{ nav.title }}</p>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import {Navigations} from "@/router/navigations";

export default {
  name: "UserMenu",
  props: {
    isTitleWhite: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    isAdmin() {
      return JSON.parse(localStorage.getItem('user'))
    },
    navigations() {
      let tempNav = [];
      if ((this.$route.name === 'sobity' || this.$route.name === 'admins') && this.isAdmin.role !== 'user') {
        tempNav.push(Navigations[0]);
        tempNav.push(Navigations[1]);
      } else {
        Navigations.forEach(item => {
          if (item.main && item.name !== 'admins') {
            tempNav.push(item)
          }
        });
      }
      return tempNav
    }
  },
}
</script>

<style scoped lang="scss">
.menu {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;

  @media (max-width: 900px) {
    height: auto;
    justify-content: center;
    box-shadow: 0 1px 8px rgb(0 0 0 / 10%);
  }

  .menu-item-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 5px;
    height: 100%;
    border-bottom: 2px solid transparent;

    &:hover {
      background-color: transparent !important;
      border-bottom-color: #354B9C;

      p {
        color: #354B9C !important;

        &.custom-cls {
          color: rgba(223, 224, 224, 0.7) !important;
        }
      }

      .down_icon i {
        color: #354B9C !important;
      }
    }

    .icon-white {
      i {
        color: #ffffff;
      }
    }

    .down_title p {
      font-size: 15px;
    }

    .custom-cls {
      color: #ffffff;
    }

    .down_icon i {
      font-size: 20px;
    }

    &.active {
      background-color: transparent !important;
      border-bottom-color: #354B9C;

      p {
        color: #354B9C !important;

        &.custom-cls {
          color: rgba(223, 224, 224, 0.7) !important;
        }
      }

      .down_icon i {
        color: rgba(223, 224, 224, 0.7) !important;
      }
    }
  }
}
</style>